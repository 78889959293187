
import React, { useState, useEffect } from "react";
import axios from "axios";
import { chapterDatalist,videoUpload,allQuestions, questionDatalistfilter, getQuestionDataDirectly, gradeGet, subjectGet, allactiveQuestion, GetCategoryAndSubcategory, contentAdd, chapterAllDataActive, imageAdd, chapterAllCategory, tagsAllData } from "../../../proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./content.css";
import List from "../../../components/lecture/List";
import Select from 'react-select';
import { ImCancelCircle } from "react-icons/im";
import { MultiSelect } from "react-multi-select-component";
import CkEditorComp from "components/common/CkEditorComp";
import VideoDragDrop from "./DragComponents/VideoDragDrop";
import ImageUploader from "components/common/ImageUploader";

import DragDropModule from "./DragComponents/DragDropModule";
import { useNavigate } from "react-router-dom";

export default function ContentAdd() {

  const Router = useNavigate();
  const [name, setName] = useState(null);
  const [price, setPrice] = useState(null);
  const [note, setNote] = useState(null);
  const [selected, setSelected] = useState([]);
  const [selectedcat, setSelectedcat] = useState([]);
  const [optionsCat, setoptionsCat] = useState([]);
  const [options, setoptions] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [checkselectederror, setcheckselectederror] = useState(null);
  const [checkselectederror2, setcheckselectederror2] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [videoRedUrl, setVideoRedUrl] = useState([]);
  //console.log(videoRedUrl,"videoRedUrlvideoRedUrlvideoRedUrl");
  const [selectedChapter, setSelectedChapter] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [CatSubData, setCatSubData] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [image, setImage] = useState('');
  const [imagedata, setImageData] = useState(null);
  //console.log(imagedata,"jhgfdghj");
  const [TagData, setTagData] = useState([]);
  const [sale_price, setSaleprice] = useState(null);
  const [checked, setChecked] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questionsDefaultData, setQuestionsDefaultData] = useState([]);
  const [questionData, setQuestionData] = useState("");
  const [gradeData, setGradeData] = useState("");
  const [subjectData, setSubjectData] = useState("");
  const [slug, setSlug] = useState(null);

  console.log(questionData, "questionData");



  // API CALL FOR BIDEFAULT VALUES FOR QUESTIONS  AND ANSWERS start --->
  const getQuesstionsDefalult = async () => {
    const dataQ = await axios.get(getQuestionDataDirectly).then((res) => {
      return res.data.data;
    }).catch((err) => {
      console.log('Error In Getting Question Data', err);
      return [];
    });

   
    setQuestionsDefaultData(dataQ);
  }
  useEffect(() => {
    getQuesstionsDefalult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  // API CALL FOR BIDEFAULT VALUES FOR QUESTIONS  AND ANSWERS start --->


  // const [image, setImage] = useState(null);
  const [err, setErr] = useState();
  const [description, setDescription] = useState(null);
  const [WhatWillLearn, setWhatWillLearn] = useState(null);
  const [Requirements, setRequirements] = useState(null);

  const [status, setStatus] = useState("active");
  const [Certified, setCertified] = useState("true");
  const [feature, setFeature] = useState("true");
  const [parentCategory, setParentCategory] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [selectedQuestiondata, setSelectedQuestiondata] = useState([]);
  const [isQuwstionAddPopup, setIsQuwstionAddPopup] = useState(false);
  const [isQuwstionViewPopup, setIsQuwstionViewPopup] = useState(false);

  const [isSticky, setIsSticky] = useState(false);


  const [videoFiles, setVideoFiles] = useState([]);
  const [videoUpdated, setVideoUpdate] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);

  const handleVideoFileChange = async (e) => {
    e.preventDefault();
    setVideoLoading(true);
    const chosenFiles = Array.prototype.slice.call(videoFiles);

    chosenFiles.map((data) => {

      if (!data) {
        alert("Please select a video file to upload.");
        return;
      }
      const chunkSize = 5 * 1024 * 1024;
      const totalChunks = Math.ceil(data.size / chunkSize);
      let chunkNumber = 0;
      let start = 0;
      let end = chunkSize;
      const uploadNextChunk = async () => {
        if (chunkNumber < totalChunks) {
          const chunk = data.slice(start, end);
          console.log(chunk, 'chunkchunk');
          const formData = new FormData();
          formData.append("Chunk", chunk);
          formData.append("ChunkNumber", chunkNumber);
          formData.append("TotalChunks", totalChunks);
          formData.append("FileName", data.name);
          formData.append("FileSize", data.size);
          // formData.append("File Size:", videoBuffer.length)

          try {
            const response = await axios.post(
              videoUpload,
              formData,
              {
                headers: {
                  "Content-type": "multipart/form-data",
                },
                withCredentials: true,
              }
            ).then((res) => {

              if (res.status === 201) {
                setVideoRedUrl(prevData => [...prevData, res.data.url]);
                setVideoLoading(false);
                setVideoUpdate(true);
                toast.success("Video uploaded Successfully", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              else {
                console.log(res, 'resresres');
                const temp = `Chunk ${chunkNumber + 1}/${totalChunks} uploaded successfully`;
              }

              chunkNumber++;
              start = end;
              end = start + chunkSize;
              uploadNextChunk();

            })
              .catch((err) => {
                console.log(err);
                setVideoLoading(false);
              });
          } catch (error) {
            setVideoLoading(false);
            console.error("Error uploading chunk:", error);
            toast.error("Error During the uploading the video!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          
          setVideoFile(null);
          setVideoLoading(false);
        }
      };

      uploadNextChunk();
    })
  };
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Set isSticky based on scroll position
      if (offset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach the event listener on component mount
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [chapterData, setChapterData] = useState(null);
  const [perchapterData, setPerChapterData] = useState(null);
  const [perOptionData, setPerOptionData] = useState(null);

  const gradeDataFetch = async () => {
    await axios
      .get(gradeGet)
      .then((res) => {
        setGradeData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setGradeData(null);
      });
  };
  const subjectDataFetch = async () => {
    await axios
      .get(subjectGet)
      .then((res) => {
        setSubjectData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setSubjectData(null);
      });
  };
  // Ck Editor Data Starts-----------------------{
  const QuestionDataFromEditor = (e) => {
    setQuestionData(e);
  };
  // Ck Editor Data Ends-----------------------}

  const getAllCategorysData = async () => {

    await axios.get(chapterAllDataActive, {
      withCredentials: true
    }).then(res => {
      //console.log(res.data.array,'resres');
      setPerChapterData(res.data.array);
      setChapterData(res.data.array);
      // setChapterData(res.data.data);

    }).catch(err => {
      setChapterData(null);
    });

    await axios.get(chapterAllCategory, {
      withCredentials: true
    }).then(res => {
      //console.log(res.data.array,'resres');
      setoptionsCat(res.data.array);
      // setChapterData(res.data.data);
    }).catch(err => {
      setoptionsCat(null);
    });

    await axios.get(allQuestions, {
      withCredentials: true
    }).then(res => {
      //console.log(res.data.array,'resres');
      setPerOptionData(res.data.array);
      setoptions(res.data.array);
      // setChapterData(res.data.data);

    }).catch(err => {
      setoptionsCat(null);
    });
  }


  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isImageLoadingUploading, setIsImageLoadingUploading] = useState(false);
  const handleSubmitImage = async (e) => {
    e.preventDefault()
    setIsImageLoadingUploading(true);
    let formData = new FormData();
    formData.append('file', image);

    axios
      .post(
        imageAdd,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
          withCredentials: true,
        }
      ).then((res) => {
        setIsImageLoadingUploading(true);
        setIsImageUploaded(true);
        if (res.status === 201) {
          toast.success("Image uploaded Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setImageData(res.data.path);
          setIsImageUploaded(true);
          setErr(null);
        }
      })
      .catch((err) => {
        setIsImageLoadingUploading(true);
        setIsImageUploaded(false);
        if (err.response.status === 400) {
          toast.error("Please upload Image!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });
  }
  useEffect(() =>{
    setIsImageUploaded(false);
    setIsImageLoadingUploading(false)
  },[image])

  ///////-----Submit Data Starts------//////////
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmited(true)
    await axios
      .post(
        contentAdd,
        {
          name,
          description,
          status,
          videoRedUrl
        
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success("Content Created Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setErr(null);
        setSubmited(false);
        setTimeout(() =>{
         // Router('/admin/content');
        },3000);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSubmited(false);
          setErr(err.response.data.errors);
        }
        else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });

    // console.log(selectedValues, 'selectedValues');
    // console.log(selectedValues, 'selectedValues');
  }

  const fetchCatSubData = async () => {
    axios
      .get(GetCategoryAndSubcategory)
      .then((res) => {
        setCatSubData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setCatSubData([]);
      });
  };
  // CATEGORY OPTION .......... STARTING..........{
  const CategoryReData = [];
  if (CatSubData) {
    for (let i = 0; i < CatSubData.length; i++) {
      const id = CatSubData[i].id;
      const catName = CatSubData[i].name;
      const is_dissabled = CatSubData[i].status === "inactive" ? true : false;
      const option = {
        value: id,
        label: catName,
        disabled: is_dissabled,
      };
      CategoryReData.push(option);
      const subCatData = CatSubData[i].subcategories;
      if (subCatData[0]) {
        for (let index = 0; index < subCatData.length; index++) {
          const element = subCatData[index];
          const subCatId = element.id;
          const subCatName = element.name;
          const is_dissabled = element.status === "inactive" ? true : false;
          const option = {
            value: subCatId,
            label: subCatName,
            disabled: is_dissabled,
          };
          CategoryReData.push(option);
        }
      }
    }
  }
  // CATEGORY OPTION .......... ENDING..........}

  // TAG OPTION .......... STARTING..........{
  const TagReData = [];
  if (TagData) {
    for (let i = 0; i < TagData.length; i++) {
      const id = TagData[i].id;
      const tagName = TagData[i].name;
      const is_dissabled = TagData[i].status === "inactive" ? true : false;
      const option = {
        value: id,
        label: tagName,
        disabled: is_dissabled,
      };
      TagReData.push(option);
    }
  }
  const getChapterdata = async () => {
    axios
      .post(
        chapterDatalist,
        {
          selectedTags,
          selectedCategories,
          selectedSubject,
          selectedGrade
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res, 'resresres');
        setChapterData(res.data.filterData);
      })
      .catch((err) => {
        console.log(err);
        setChapterData([]);
      });
  };
  const getQuestiondata = async () => {
    axios
      .post(
        questionDatalistfilter,
        {
          selectedTags,
          selectedCategories,
          selectedSubject,
          selectedGrade
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res, 'setoptions questions');
        setoptions(res.data.filterData);
      })
      .catch((err) => {
        console.log(err);
        setoptions([]);
      });
  };

  const fetchTagData = async () => {
    axios
      .get(tagsAllData)
      .then((res) => {
        setTagData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setTagData([]);
      });
  };
  useEffect(() => {
    fetchCatSubData();
    fetchTagData();
  }, []);
  useEffect(() => {
    getAllCategorysData();

  }, [])

  useEffect(() => {
    if (selected.length > 0) {
      setSelectedQuestiondata(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (selectedChapter.length > 0) {
      setSelectedChapter(selectedChapter);
    }

  }, [selectedChapter])
  useEffect(() => {
    gradeDataFetch();
    subjectDataFetch();
  }, []);

  return (

    <>

      <div className="mx-auto flex w-full  gap-7 py-6">

        <div className=" h-full w-[100%] rounded-md bg-white px-5 py-5  ">
          <div className="category_form mx-auto mt-3 w-full max-w-screen-xl rounded-lg bg-white p-6 dark:bg-navy-800">
            <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white">
              Add Content
            </h1>

            <div className="mb-4 flex gap-5 ">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                  Title
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="name"
                  type="text"
                  placeholder="Content Title"
                  value={name}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setName(newValue);

                    // Automatically generate slug based on the name
                    const newSlug = newValue
                      .toLowerCase() // Convert to lowercase
                      .replace(/\s+/g, "-") // Replace whitespace with hyphen
                      .replace(/[^a-z0-9-]/g, ""); // Remove special characters except hyphen

                    setSlug(newSlug);
                  }}
                />
                {err && err.name ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.name}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            

            </div>
            <div className="mb-4">
              <label className="mb-2 block text-sm font-bold " htmlFor="slug">
                Video
              </label>

              <VideoDragDrop setVideoFiles={setVideoFiles} />
              {
                videoFiles[0] ? (
                  <>
                    {
                      videoLoading ? (
                        <>
                          <div className="text-end mt-3 ">
                            <button
                              disabled
                              className="bg-blue-200 text-white py-2 px-4 rounded-md hover:bg-blue-300 focus:outline-none"
                            >
                              Uploading....
                            </button>
                          </div>
                        </>
                      ) : <>
                        {
                          videoUpdated ? (
                            <>
                            <div className="text-end mt-3 ">
                          <button
                            className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none"
                          >
                            Uploaded
                          </button>
                        </div> 
                            </>
                          ):<>
                          <div className="text-end mt-3 ">
                          <button
                            onClick={handleVideoFileChange}
                            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none"
                          >
                            Upload Videos
                          </button>
                        </div> 
                          </>
                        }
                        </>
                    }
                  </>
                ) : <></>
              }

            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold "
                htmlFor="description"
              >
                Description
              </label>
              <CkEditorComp
                handleData={(e) => {
                  setDescription(e);
                }}
              />
              {err && err.description ? (
                <>
                  <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    Description is Required!
                  </p>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
           

            <div className="mb-4 flex  gap-5">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="status">
                  Status
                </label>

                <select
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"

                  id="status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>
            {/* status end  */}

            <div className="flex justify-end">
              <button
                className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
                type="submit"
                onClick={handleSubmit}
              >
                Add Content
              </button>
            </div>
          </div>
        </div>
      
      </div>
      <ToastContainer />
    </>
  );
}
