import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import 'tailwindcss/tailwind.css';
const ImageUpload = ({setImageFile}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const onDrop = (acceptedFiles) => {
    const image = acceptedFiles[0];
    setSelectedImage(image);
    setImageFile(image)
    setPreviewUrl(URL.createObjectURL(image));
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*', maxFiles: 1 });
  return (
    <div className="full mx-auto mt-10 h-fit">
        <p className=' text-primaryBlue font-semibold mb-2 ' >Feature Image</p>
      <div
        {...getRootProps()}
        className={`border-2 border-dashed rounded-md p-4 ${
          isDragActive ? 'border-blue-500' : 'border-gray-300'
        } flex flex-col justify-center items-center cursor-pointer`}
      >
        <input {...getInputProps()} />
        <p className="text-center">
          {isDragActive ? 'Drop the image here' : 'Drag and drop an image here, or click to select'}
        </p>
        {selectedImage && (
        <div className="mt-4  ">
          <h2 className="text-lg font-semibold">Selected Image:</h2>
          <img src={previewUrl} alt="Selected" className="mt-2 h-[200px] rounded-md" />
        </div>
      )}
      </div>
    
    </div>
  );
};
export default ImageUpload;