import React from 'react'
import { Link } from "react-router-dom";
import axios from "axios";
import { questionDelete } from "proxyUrl";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { RiEditCircleFill, RiShowersFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Records = ({ nPages,getQuestionFun, data }) => {
  //console.log(data, 'datadatadatadatadata');

  const handleDelete = async (id) => {
    await axios.delete(`${questionDelete}/${id}`, {
      withCredentials: true
    }).then((res) => {
      //console.log(res,'res1111');
      if (res.status === 201) {
        toast.success("Question Deleted Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        getQuestionFun();

      }

    }).catch((err) => {
      if (err.response.status === 400) {
        toast.success("Question Not Found!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
      if (err.response.status === 500) {
        toast.success("Internal Server Error!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    })
  }
  console.log(nPages,'nPagesnPages')
  return (
    <table className="min-w-full  overflow-hidden rounded-lg bg-white  dark:bg-navy-900">
      <thead className=" overflow-hidden rounded-lg text-white">
        <tr>
          <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
            Id
          </th>
          <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
            Name
          </th>

          <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
            Topic
          </th>
          <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
            Grade
          </th>
          <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
            Subject
          </th>
          <th className=" border bg-primaryBlue py-2 px-4 text-start dark:border-navy-900 dark:bg-navy-900">
            Status
          </th>
          <th className=" border bg-primaryBlue py-2 px-4 text-center dark:border-navy-900 dark:bg-navy-900">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        
        {data?.length > 0
          ? data?.map((question, index) => {


            const name = question.questions.substring(0, 30) + '...';
            const slug = question.slug.substring(0, 30) + '...';
            return (
              <tr key={index}>
                <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                  {nPages+index+1}
                </td>
                <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                  {name}
                </td>
                {/* <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                  {slug}
                </td> */}
                <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                  {question?.topic?.name}
                </td>
                <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                  {question?.question_grades?.grade?.name}
                </td>
                <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                  {question?.subject?.subject?.name}
                </td>
                <td className="border py-2 px-4   dark:border-navy-800   dark:text-white">
                  <p className=" w-fit  rounded-full bg-blueSecondary py-1 px-4 text-sm text-white">
                    {question.status}
                  </p>
                </td>

                <td className="flex  items-center  justify-center  gap-3 border px-4  text-center   dark:border-navy-800 dark:text-white">
             
                  <Link
                    to={`/admin/questions/edit/${question.id}`}
                    className="focus:shadow-outline mr-2 rounded py-1    font-bold text-white focus:outline-none"
                  // onClick={() => handleEdit(question.id)}
                  >
                    <RiEditCircleFill className=" text-3xl text-primaryBlue hover:text-primaryBlue/80 dark:text-white" />
                  </Link>
                  <button
                    className="focus:shadow-outline rounded font-bold text-white focus:outline-none"
                    onClick={() => handleDelete(question.id)}
                  >
                    <MdDelete className=" text-3xl text-orange-600 hover:text-orange-500 " />
                  </button>
                </td>
              </tr>
            );
          })
          : <tr><td class="text-left md:text-center" colspan="5" >No data Found</td> </tr> }
      </tbody>
      <ToastContainer />
    </table>

  )
}

export default Records 