import React from 'react'

export default function LoaderComp({submited}) {
  return (
   <>
   
   {
        submited ? (
          <>
          <div className="fixed h-full w-full  bg-white/10 rounded-lg  backdrop-blur-sm z-[2] top-0 left-0 h-[100%] flex items-center justify-center "><div className="w-16 h-16 relative z-10 border-4 border-dashed rounded-full animate-spin border-blueSecondary "></div></div>
         </>
        ) : <></>
      }
   
   </>
  )
}
