
import React, { useEffect } from "react";


function disableBackButton() {
  window.history.pushState(null, null, window.location.pathname);
  window.addEventListener("popstate", function (event) {
    window.history.pushState(null, null, window.location.pathname);
  });
}
const Dashboard = () => {
  useEffect(() => {
    disableBackButton();
  }, []);
  return (
    <div>
      {/* Card widget */}
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
     
      </div>
    
    </div>
  );
};

export default Dashboard;
