import React, { useState, useEffect } from "react";
import { tagsAllData, tagDelete } from "proxyUrl";
import { Link } from "react-router-dom";
import axios from "axios";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { RiEditCircleFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import Pagination from "components/common/Pagination";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderComp from "components/common/LoaderComp";

export default function Index() {

  const [tagData, setTagData] = useState([]);
  const [submited, setSubmited] = useState(true);
  // pagination start
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  // pagination end 
  // getting all data tags starts----------------------
  const getAllTagsData = async () => {

    await axios.get(tagsAllData, {
      withCredentials: true
    }).then(res => {
      setTagData(res.data.data);
      setSubmited(false);

    }).catch(err => {
      setTagData(null);
    })

  }
  // getting all data tags ends----------------------
  useEffect(() => {
    getAllTagsData();
  }, [])


  const handleDelete = async (id) => {
    await axios.delete(`${tagDelete}/${id}`, {
      withCredentials: true
    }).then((res) => {
      toast.success("Tag Deleted Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      getAllTagsData();
    }).catch((err) => {
      if (err.response.status === 400) {
        toast.success("Tag Not Found!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
      if (err.response.status === 500) {
        toast.success("Internal Server Error!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    })
  }


  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = tagData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(tagData.length / recordsPerPage);


  return (
    <>
      <div className="mx-auto w-full rounded-lg overflow-hidden bg-white dark:bg-navy-800 max-w-screen-xl py-2 px-6">
        <div className=" flex w-full  justify-between items-center pb-2 ">
          <h1 className="mb-4 text-2xl font-bold dark:text-white  ">Tags List</h1>
          <div className="">
            <Link className=" px-4 py-2 bg-white border-[3px] rounded-full font-bold hover:bg-blueSecondary hover:text-white border-primaryBlue/70" to={'/admin/tag/add'}> Add Tag </Link>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full  rounded-lg overflow-hidden dark:bg-navy-900  bg-white">
            <thead className=" rounded-lg overflow-hidden text-white">
              <tr>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Name</th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Slug</th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Description</th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Status</th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
            <LoaderComp submited={submited} />

              {
                tagData[0] ? (
                  <>
                    {currentRecords.map((tag) => (
                      <tr key={tag.id}>
                        <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">{tag.name}</td>
                        <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">{tag.slug ? tag.slug: 'N/A' }</td>
                        <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">{tag.description? tag.description: 'N/A'}</td>
                        <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">
                          <p className=" text-sm  py-1 bg-blueSecondary w-fit px-4 text-white rounded-full" >Active</p>
                        </td>
                        <td className="border  dark:text-white  flex  gap-3 justify-center items-center  dark:border-navy-800   px-4 text-center">
                          <Link to={`/admin/tag/edit/${tag.id}`}
                            className="focus:shadow-outline mr-2 rounded py-1    font-bold text-white focus:outline-none"
                          // onClick={() => handleEdit(category.id)}
                          >
                            <RiEditCircleFill className=" text-3xl hover:text-primaryBlue/80 dark:text-white text-primaryBlue" />
                          </Link>
                          <button
                            className="focus:shadow-outline rounded font-bold text-white focus:outline-none"
                            onClick={() => handleDelete(tag.id)}
                          >
                            <MdDelete className=" text-3xl text-orange-600 hover:text-orange-500 " />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : <>
                  <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">No Data</td>
                  <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">No Data</td>
                  <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">No Data</td>
                  <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">No Data</td>
                  <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">No Data</td>
                  <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">N/A</td>
                </>
              }
            </tbody>
          </table>
        </div>
        {
          nPages != 1 ?
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            :
            ''

        }
      </div>
      <ToastContainer />
    </>
  );
}
