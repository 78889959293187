import React from 'react'
import { Link } from 'react-router-dom'
const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  

  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
  const paginationno = [];
  //console.log(pageNumbers,'secondParpageNumberspageNumberstsecondPart')

//   if(pageNumbers && pageNumbers.length > 8){

  
// const midIndex = Math.floor(pageNumbers.length / 2);
// console.log(midIndex,'midIndexmidIndex');
// const firstPart = pageNumbers.slice(0, midIndex);
// const secondPart = pageNumbers.slice(midIndex);
// console.log(firstPart,'firstPartfirstPart')
// console.log(secondPart,'secondPartsecondPart')



//   }
//   else{
//    const  paginationno = pageNumbers;
//   }
 // pageNumbers

  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1)
  }
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1)
  }
  return (
    <div key={currentPage} className="w-svw overflow-auto  flex items-center justify-center " aria-label="Page  navigation example ">
      <ul class="inline-flex -space-x-px text-sm  py-10 w-full ">
        <li>
          <Link to="#" class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 rounded dark:text-gray-400 dark:hover:bg-gray-700 mr-5 border dark:hover:text-white" onClick={goToPrevPage} >Previous</Link>
        </li>
        {pageNumbers.map(pgNumber => (
          <>
            {currentPage+1 === pgNumber ?
              <li>
                <Link to="#" aria-current="page" class=" bg-primaryBlue flex items-center justify-center px-3 h-8 leading-tight  text-white border border-gray-300 rounded hover:bg-green-400 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700  dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => setCurrentPage(pgNumber-1)}>{pgNumber}</Link>
              </li>
              :
              <li>
                <Link to="#" aria-current="page" class="text-gray-500 flex items-center justify-center px-3 h-8 leading-tight bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 rounded mx-1 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => setCurrentPage(pgNumber-1)}>{pgNumber}</Link>
              </li>}
          </>
        ))}
        <li>
          <Link to="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border ml-5 border-gray-300 rounded hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={goToNextPage}>Next</Link>
        </li>
      </ul>
    </div>
  )
}

export default Pagination


