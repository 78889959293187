import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./chapter.css";
import { chapterDataById } from "proxyUrl";
import { topicGet, questionGet, chapterUpdate, imageAdd, gradeGet, subjectGet, GetCategoryAndSubcategory, tagsAllData, allactiveQuestion } from "../../../proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import List from "../../../components/lecture/List";
import makeAnimated from 'react-select/animated';
import { MultiSelect } from "react-multi-select-component";
import CkEditorComp from "components/common/CkEditorComp";
import Select from 'react-select';
import LoaderComp from "components/common/LoaderComp";

export default function CourseEdit() {
  const Router = useNavigate();
  const [name, setName] = useState(null);
  const [slug, setSlug] = useState(null);
  const [image, setImage] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedQuestion, setselectedQuestion] = useState([]);
  const [SelectedQuestiondata, setSelectedQuestiondata] = useState([]);
  const [gradeData, setGradeData] = useState("");
  const [subjectData, setSubjectData] = useState("");
  const [description, setDescription] = useState(null);
  const [err, setErr] = useState();
  const [price, setPrice] = useState(null);
  const [note, setNote] = useState(null);
  const [CatSubData, setCatSubData] = useState([]);
  const [TagData, setTagData] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [sale_price, setSaleprice] = useState(null);
  const [status, setStatus] = useState("active");
  const [categoryType, setCategoryType] = useState('0');
  const [parentCategory, setParentCategory] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [catData, setCatData] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [imagedata, setImageData] = useState(null)
  const [options, setoptions] = useState([]);
  const [optionsCat, setoptionsCat] = useState([]);
  const [optionstopic, setOptionstopic] = useState([
    { topic: [], questiondata: [] },
  ]);
  const [topicData, setTopicData] = useState("");
  const [selectedTopic, setSelectedTopic] = useState([]);

  const Navigate = useNavigate();
  const [catDataFiroApi, setCatDataFromApi] = useState(null);
  const [SelectedQuesss, setSelectedQuesss] = useState([]);
  // Get ID from URL
  const params = useParams();
  const { id } = params;
  const handleSelectChange = (event) => {
    const options = event.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }

    setSelectedValues(selected);

  };
  const getChaptersDataId = async () => {

    await axios.get(`${chapterDataById}/${id}`, {
      withCredentials: true
    }).then(res => {
      // console.log(res.data,'casasasaatDatacatData')
      setCatDataFromApi(res.data);
      setCatData(res.data);
      //console.log(catData,'catDatacatData')
    }).catch(err => {
      setCatData(null);
    })

  }
  const getAllCategorysData = async () => {



    await axios.get(allactiveQuestion, {
      withCredentials: true
    }).then(res => {
      //console.log(res.data.array,'resres');
      setoptions(res.data.array);
      // setChapterData(res.data.data);

    }).catch(err => {
      setoptionsCat(null);
    })

  }
  const handleImageChange = (e) => {
    const selectedImages = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files,
    }
    setImage(selectedImages);

  };

  const handleCategoryTypeChange = (e) => {
    setCategoryType(e.target.value);
    // Reset parent category when switching between category and subcategory
    setParentCategory(null);
  };

  ///////-----Submit Data Starts------//////////
  const handleSubmit = async (e) => {
    // console.log(optionstopic, 'optionstopicoptionstopicoptionstopicoptionstopic');

    e.preventDefault();
    setSubmited(true)
    
    const SelectedQuesss = optionstopic.map((item) => ({
      question_id: item.questiondata.map((option) => option.value), // Assuming `questionId` is part of each question
      // selectedOptions: item.questiondata.map((option) => option.value), // Extract only values (IDs)
    }));  
    console.log("ques", SelectedQuesss);

    await axios
      .post(
        chapterUpdate,
        {
          id,
          name,
          slug,
          description,
          status,
          selectedTags,
          selectedCategories,
          selectedSubject,
          selectedGrade,
          imagedata,
          SelectedQuestiondata,
          optionstopic,
          SelectedQuesss

        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success("Chapter updated Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setErr(null);
        setSubmited(false);
        setTimeout(() =>{
          Router('/admin/chapter');
        },3000);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSubmited(false);
          setErr(err.response.data.errors);
        }
        else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });



  }

  // CATEGORY OPTION .......... STARTING..........{
  const CategoryReData = [];
  if (CatSubData) {
    for (let i = 0; i < CatSubData.length; i++) {
      const id = CatSubData[i].id;
      const catName = CatSubData[i].name;
      const is_dissabled = CatSubData[i].status === "inactive" ? true : false;
      const option = {
        value: id,
        label: catName,
        disabled: is_dissabled,
      };
      CategoryReData.push(option);
      const subCatData = CatSubData[i].subcategories;
      if (subCatData[0]) {
        for (let index = 0; index < subCatData.length; index++) {
          const element = subCatData[index];
          const subCatId = element.id;
          const subCatName = element.name;
          const is_dissabled = element.status === "inactive" ? true : false;
          const option = {
            value: subCatId,
            label: subCatName,
            disabled: is_dissabled,
          };
          CategoryReData.push(option);
        }
      }
    }
  }
  // CATEGORY OPTION .......... ENDING..........}

  // TAG OPTION .......... STARTING..........{
  const TagReData = [];
  if (TagData) {
    for (let i = 0; i < TagData.length; i++) {
      const id = TagData[i].id;
      const tagName = TagData[i].name;
      const is_dissabled = TagData[i].status === "inactive" ? true : false;
      const option = {
        value: id,
        label: tagName,
        disabled: is_dissabled,
      };
      TagReData.push(option);
    }
  }

  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Set isSticky based on scroll position
      if (offset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach the event listener on component mount
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const gradeDataFetch = async () => {
    await axios
      .get(gradeGet)
      .then((res) => {
        setGradeData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setGradeData(null);
      });
  };
  const subjectDataFetch = async () => {
    await axios
      .get(subjectGet)
      .then((res) => {
        setSubjectData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setSubjectData(null);
      });
  };
  const fetchCatSubData = async () => {
    axios
      .get(GetCategoryAndSubcategory)
      .then((res) => {
        setCatSubData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setCatSubData([]);
      });
  };
  const fetchTagData = async () => {
    axios
      .get(tagsAllData)
      .then((res) => {
        setTagData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setTagData([]);
      });
  };
  const topicDataFetch = async () => {
    // console.log(selectedSubject, 'selectedSubjectselectedSubject');
    await axios
      .post(
        topicGet,
        {
          subjectId: selectedSubject?.value ? selectedSubject?.value : 0,
          gradeId: selectedGrade.value ? selectedGrade.value:0
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setTopicData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setTopicData(null);
      });
  };
  useEffect(() => {
    topicDataFetch();
  }, [selectedSubject]);
  useEffect(() => {
    gradeDataFetch();
    subjectDataFetch();
  }, []);
  useEffect(() => {
    fetchCatSubData();
    fetchTagData();
  }, []);
  const handleSubmitImage = async (e) => {
    e.preventDefault()
    // console.log(image.data, 'image111');


    let formData = new FormData()
    formData.append('file', image.data[0])

    axios
      .post(
        imageAdd,
        formData,

        {
          headers: {
            "Content-type": "multipart/form-date",
          },
          withCredentials: false,
        }
      ).then((res) => {
        if (res.status === 201) {

          toast.success("Image uploaded Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setImageData(res.data.path);
          setErr(null);
          setSubmited(false);
        }

      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.error("Please upload Image!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });
  }
  useEffect(() => {
    getChaptersDataId();
  }, [id]);
  useEffect(() => {

    if (catDataFiroApi) {
      setName(catDataFiroApi.CatData.name);
      setDescription(catDataFiroApi.CatData.description);
      setSlug(catDataFiroApi.CatData.slug);
      setStatus(catDataFiroApi.CatData.status);
     // setDescription(catDataFiroApi.CatData.description);
      setNote(catDataFiroApi.CatData.important_notes);

      setSelectedGrade({
        label: catDataFiroApi.CatData?.grade?.name,
        value: catDataFiroApi.CatData?.grade?.id,
      });
      setSelectedSubject({
        label: catDataFiroApi.CatData?.subject?.name,
        value: catDataFiroApi.CatData?.subject?.id,
      });


      // setSelectedSubject({
      //   label: catDataFiroApi.CatData?.subject?.name,
      //   value: catDataFiroApi.CatData?.subject?.id,
      // });


      const newOptions = [...optionstopic];
      
      catDataFiroApi.chaptertopic?.chapter.forEach(function (data, index) {
        const fulltopic = [];
        const arrayQuestion = [];
        fulltopic['topic'] = {
          label: data.topic?.name,
          value: data.topic?.id.toString(),
        };
       
        data.topics.forEach(function (que) {

         arrayQuestion.push({
            label: que.question.questions,
            value: que.question.id.toString(),
          });

        })
        fulltopic['questiondata'] = arrayQuestion;
        console.log(fulltopic,'fulltopicfulltopic');
        newOptions[index] = { ...fulltopic };
     }
      );
      console.log(newOptions,'newOptionsnewOptions');
      setOptionstopic(newOptions);

      // tag
      var arrayTag = new Array();
      catDataFiroApi.chaptertag.forEach(function (element, i) {
      if (element.tag?.name != null) {
          var co_data1 = {
            label: element.tag?.name,
            value: element.tag?.id.toString(),
          };
          arrayTag.push(co_data1);
        }

      });
      setSelectedTags(arrayTag);
      // console.log("arraytestttss",arrayTag);

      // category

      var arrayCat = new Array();
      catDataFiroApi.chaptercategory.forEach(function (element, i) {
        //console.log(element.tag,'elementelement');
        if (element.category?.name != null) {
          var co_data1 = {
            label: element.category?.name,
            value: element.category?.id.toString(),
          };
          arrayCat.push(co_data1);
        }

      });
      setSelectedCategories(arrayCat);

      // subject 
      const selectedImages = {
        preview: `http://localhost:4001/${catDataFiroApi.CatData.image}`,
        data: catDataFiroApi.CatData.image,
      }
      setImage(selectedImages);

      // var arrayQuestion = new Array();
      // catDataFiroApi.coursequestion.forEach(function (element, i) {
      //   if (element.question != null) {
      //     var co_data1 = {
      //       label: element.question?.questions,
      //       value: element.question?.id.toString(),
      //     };
      //     arrayQuestion.push(co_data1);
      //   }

      // });
      // setselectedQuestion(arrayQuestion);

    }

  }, [catDataFiroApi]);

  //console.log(selectedGrade,'selectedGradeselectedGrade');
  useEffect(() => {
    if (selectedQuestion.length > 0) {
      setSelectedQuestiondata(selectedQuestion);
    } 
  }, [selectedQuestion])
  // console.log("qquuesss",selectedQuestion);
  
 
  useEffect(() => {
    getAllCategorysData();

  }, [])
  useEffect(() => {
    questionDataFetch();
  }, [selectedTopic]);
  // useEffect(() => {
  //   questionDataFetch();
  // }, [selectedTopic]);

  const questionDataFetch = async () => {
    await axios
      .post(
        questionGet,
        {
          topicid: selectedTopic ? selectedTopic : 0,
          // subtopicid: selectedSubTopic ? selectedSubTopic : 0,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setoptions(res.data.formattedData);
      })
      .catch((err) => {
        console.log(err);
        setoptions([]);
        //  setSubTopicData(null);
      });
  };
  const handleInputChange = (index, field, value) => {
    const newOptions = [...optionstopic];
    newOptions[index][field] = value;
    setOptionstopic(newOptions);
  };



  // Function to remove a set of input fields
  const removeInputFields = (index) => {
    const updatedFields = [...optionstopic];
    updatedFields.splice(index, 1);
    setOptionstopic(updatedFields);
  };

  const handleselectedquestions = (index, field, value) => {
    const updatedOptions = [...optionstopic];
  
    // Update questiondata with selected options and include IDs
    updatedOptions[index][field] = value.map(option => ({
      id: option.id,
      value: option.value, // Assuming each option has an `id` and `value`
      label: option.label,
    }));
  
    // setOptionstopic(updatedOptions); // Update state
    setSelectedQuesss(updatedOptions); 
  };
  
  const renderInputFields = () => {
    return optionstopic?optionstopic.map((option, index) => (
      <div key={index}>
        <div className=" border border-blueSecondary px-7  py-4 ">
          <div className="">
            <p className=" pb-1 font-bold text-primaryBlue">
              Topic {index + 1}
            </p>
          </div>
          <div className=" flex w-full  md:w-1/2 lg:w-full">
            <div class="w-[100%] ">
              <div className="mb-0 w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Topic
                </label>
                <Select
                  defaultValue={optionstopic[index]?.topic}
                  // onChange={setSelectedTopic}
                  // value={optionstopic}
                  value={optionstopic[index].topic ? optionstopic[index].topic : []}
                  // onChange={(e) =>
                  //   handleInputChange(index, "topic", e)
                  // }
                  onChange={(e) =>
                    handleselectedquestions(index, "topic", e)
                  }

                  options={topicData ? topicData : []}
                />


                {err && err["topic"] ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err["topic"]}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

            </div>
          </div>
          <div className="  flex ">
            <div className="w-[100%]">
              <div className=" w-full  md:w-1/2 lg:w-full">
                <div class="mb-4">
                  <label
                    className="mb-2 block text-sm font-bold "
                    htmlFor="description"
                  >
                    Add Question
                  </label>
                  <MultiSelect
                    options={options ? options : ''}
                    // value={optionstopic[index].questiondata ? optionstopic[index].questiondata : []}
                    value={optionstopic[index].questiondata ? optionstopic[index].questiondata : []}
                    // onChange={setSelected}
                    onChange={(e) =>
                      handleInputChange(index, "questiondata", e)
                    }
                    labelledBy="Select"
                  />
                  <List type='Question' selectedValues={optionstopic[index].questiondata ? optionstopic[index].questiondata : ''} />
                </div>
              </div>
            </div>

          </div>
          <div className="mb-4">


          </div>
          {index === 0 ? (
            <></>
          ) : (
            <>
              <div className=" flex w-full items-center justify-end">
                <button
                  className=" rounded-sm border border-red-500 px-4 py-1 text-sm text-red-500 "
                  type="button"
                  onClick={() => removeInputFields(index)}
                >
                  Remove Topic
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    )):"";
  };
  //console.log(note,selectedGrade,'selectedGradeselectedGrade')
  return (
    <>
      <div className="mx-auto flex w-full  gap-7 py-6">
        <div className=" h-full w-[70%] rounded-md bg-white px-5 py-5  ">
          <div className="category_form mx-auto mt-3 w-full max-w-screen-xl rounded-lg bg-white p-6 dark:bg-navy-800">
            <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white">
              Edit Chapter
            </h1>

            <div className="mb-4 flex gap-5 ">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                  Name
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="name"
                  type="text"
                  placeholder="Chapter Name"
                  value={name}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setName(newValue);

                    // Automatically generate slug based on the name
                    const newSlug = newValue
                      .toLowerCase() // Convert to lowercase
                      .replace(/\s+/g, "-") // Replace whitespace with hyphen
                      .replace(/[^a-z0-9-]/g, ""); // Remove special characters except hyphen

                    setSlug(newSlug);
                  }}
                />
                {err && err.name ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.name}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="slug">
                  Slug
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="slug"
                  type="text"
                  placeholder="chapter-slug"
                  value={slug}
                  onChange={(e) => {
                    setSlug(e.target.value);
                  }}
                />
                {err && err.slug ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.slug}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

            </div>

            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold "
                htmlFor="description"
              >
                Description
              </label>

              <CkEditorComp
                handleData={(e) => {
                  setDescription(e);
                }}
                predata={description ? description : ''}
              />
              {err && err.description ? (
                <>
                  <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    Description is Required!
                  </p>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="lectures_data" >
              <div className="category_form relative  mx-auto mt-3 w-full max-w-screen-xl rounded-lg   dark:bg-navy-800">
                {renderInputFields()}
                {err && err["options"] ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err["options"]}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
                <div
                  className=" mt-3 w-fit rounded-md bg-primaryBlue  px-5 py-2 text-white "
                  onClick={() =>
                    setOptionstopic([
                      ...optionstopic,
                      {
                        topic: [],
                        questiondata: [],

                      },
                    ])
                  }
                >
                  Add Topic
                </div>
                <LoaderComp submited={submited} />
              </div>
            </div>
            <div>
              < List selectedValues={selectedValues} />
            </div>



            <div className="mb-4 flex gap-5 ">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                  Important note
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="name"
                  type="text"
                  placeholder="Important note"
                  value={note}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setNote(newValue);
                  }}
                />

              </div>

            </div>

            <div className="mb-4 flex  gap-5">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="status">
                  Status
                </label>
                <select
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>

            {/* Additional fields can be added similarly */}

            <div className="flex justify-end">
              <button
                className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
                type="submit"
                onClick={handleSubmit}

              >
                Update
              </button>
            </div>
          </div>
        </div>
        <div className={` relative w-[30%]  !h-[660px] overflow-yy-scroll `}>
          <div
            className={`  ${isSticky ? "fixed top-[122px] right-7ff !h-fit rounded-md w-[22.6%] overflow-y-scroll " : "relative"
              } w-full rounded-md bg-white py-20 px-9`}
          >

            <div className=" sticky  top-0 flex flex-col  gap-3 ">

              <div className="mb-0 w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >

                  Grade
                </label>
                {
                  selectedGrade ? (
                    <>
                      <Select

                        defaultValue={selectedGrade}
                        onChange={setSelectedGrade}
                        options={gradeData}
                      />
                    </>
                  ) : <></>
                }
                {err && err.selectedGrade ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedGrade}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-0 w-full ">
                <label
                  htmlFor="Subject"
                  className="block text-sm font-medium text-gray-600"
                >
                  Subject
                </label>
                {selectedSubject ?
                  <>
                    <Select
                      defaultValue={selectedSubject}
                      onChange={(e) => {
                        setSelectedSubject(e);
                      }}
                      options={subjectData}
                    />
                  </>

                  : ''}

                {err && err.selectedSubject ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedSubject}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className=" w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Category
                </label>
                <MultiSelect
                  options={CategoryReData}
                  value={selectedCategories}
                  onChange={setSelectedCategories}
                  data={selectedCategories}
                  labelledBy="Select Category"
                  className=" mt-2"
                />
                {err && err.selectedCategories ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedCategories}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className=" w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Tag
                </label>

                <MultiSelect
                  options={TagReData}
                  value={selectedTags}
                  onChange={setSelectedTags}
                  data={selectedTags}
                  labelledBy="Select Category"
                  className=" mt-2"
                />
                {err && err.selectedTags ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedTags}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="w-full">

                <div className="mb-4 w-full px-3 md:w-1/2 lg:w-full">
                  <form onSubmit={handleSubmitImage} enctype="multipart/form-data">

                    <label className="mb-2  block text-sm font-bold" htmlFor="image">
                      Feature Image
                    </label>

                    {catDataFiroApi?.CatData?.image && image ?
                      <img src={image ? image.preview : ''} width='100' height='100' />
                      :
                      ''}
                    <hr></hr>
                    <input
                      type="file"
                      accept="image/*"
                      name="file"
                      className="focus:shadow-outline w-full appearance-none rounded   border leading-tight focus:outline-none"
                      id="image"
                      onChange={handleImageChange}
                    />


                    <button class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" type='submit'>update</button>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
