import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./course.css";
import { courseShow } from "proxyUrl";
import "react-toastify/dist/ReactToastify.css";
import ListItem from "../../../components/lecture/ListItem";
import ListQuestion from "../../../components/lecture/ListQuestion";

export default function CourseShow() {

  const [err, setErr] = useState();
  const [videoFiles, setVideoFiles] = useState([]);

  const [courseData, setCourseData] = useState(null);
  const Navigate = useNavigate();
 

  // Get ID from URL
  const params = useParams();
  const { id } = params;
 
  const getCourseDataId = async () =>{
   
    await axios.get( `${courseShow}/${id}`,{
      withCredentials:true
    }).then(res =>{
          setCourseData(res.data);
    }).catch(err =>{
        setCourseData(null);
    })

  }

 
  useEffect(() => {
    getCourseDataId();
  }, [id]);
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Set isSticky based on scroll position
      if (offset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach the event listener on component mount
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
     <div className="mx-auto flex w-full  gap-7 py-6">
        
        <div className=" h-full w-[70%] rounded-md bg-white px-5 py-5  ">
      <div className="category_form mx-auto mt-3 w-full max-w-screen-xl rounded-lg bg-white p-6 dark:bg-navy-800">
        <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white">
           Course
        </h1>

        <div className="mb-4 flex gap-5 ">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="name">
              Name
            </label>
            
          <p>{courseData?courseData?.CatData.name:''}</p>
          </div>
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="slug">
              Slug
            </label>
            <p>{courseData?`/${courseData.CatData.slug}`:''}</p>
          </div>
          
        </div>
        <div className="mb-4">
          <label
            className="mb-2 block text-sm font-bold "
            htmlFor="description"
          >
            Video
          </label>
          {courseData?courseData?.coursefile.map((file, index) => (
            <div className="video_list" key={index}>
              <p>Video {index + 1}:</p>
              <video width="400" controls>
                <source src={file.file} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )):''}
          </div>

        <div className="mb-4">
          <label
            className="mb-2 block text-sm font-bold "
            htmlFor="description"
          >
            Description
          </label>
       {/* <p>{
       
       courseData?courseData.CatData.description:''
       }</p> */}
       <div dangerouslySetInnerHTML={{__html: courseData?courseData.CatData.description:''}} />
        </div>
        <div className="lectures_data" >
            <div class="mb-4">
                  <label
                      className="mb-2 block text-sm font-bold "
                      htmlFor="description"
                    >
                      Chapter
                    </label>
          
            </div>
        </div>
        <div>
        < ListItem  type='Chapter' selectedValues={courseData?courseData.Chapterdata:[]}  />
        </div>
       
        <div className="lectures_data" >
            <div class="mb-4">
                  <label
                      className="mb-2 block text-sm font-bold "
                      htmlFor="description"
                    >
                      Question
                    </label>
          
            </div>
        </div>
        <div>
        < ListQuestion  type='Question' selectedValues={courseData?courseData.coursequestion:[]}  />
        </div>
    
        <div className="mb-4 flex  gap-5">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="status">
              Status
            </label>
          <p>{courseData?courseData.CatData.status:''}</p>
          </div>
         
        </div>

        
      </div>

      </div>
      <div className={` relative w-[30%]  !h-[700px] overflow-y-scroll `}>
      <div
          className={`  ${
            isSticky
              ? "fixed top-[122px] right-7 !h-[700px] w-[22.6%] overflow-y-scroll "
              : "relative"
          } w-full rounded-md bg-white py-20 px-9`}
        >
      
      <div className=" mb-4 flex gap-5 bg-white">
        <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="name">
              Price
            </label>
            <p>{courseData?courseData.CatData.price:''}</p>
          </div>

          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="price">
             Sale Price
            </label>
              <p>{courseData?courseData.CatData.sale_price:''}</p>
          </div>
        </div>
        <div className="mb-4 flex gap-5 ">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="name">
              Important note
            </label>
           <p>{courseData?courseData.CatData.important_note:''}</p>
          </div>
        
        </div>

        <div className="mb-4 flex gap-5 ">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="name">
             Grade
            </label>
            <p>{courseData?courseData.CatData?.grade?.name:''}</p>

          </div>
        
        </div>
        <div className="mb-4 flex gap-5 ">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="name">
             Subject
            </label>
            <p>{courseData?courseData.CatData?.subject?.name:''}</p>

          </div>
        
        </div>
        <div className="mb-4 flex gap-5 ">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="name">
             Category
            </label>
           <p>{courseData?courseData.coursecategory.map((categoory)=>(
            <>
            <p>{categoory.category.name}</p>
            </>

           )):''}</p>
          </div>
        
        </div>
        <div className="mb-4 flex gap-5 ">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="name">
             Tag
            </label>
           <p>{courseData?courseData.coursetag.map((categoory)=>(
            <>
            <p>{categoory.tag.name}</p>
            </>

           )):''}</p>
          </div>
        
        </div>
        <div className="mb-4  gap-5 ">
        <label className="mb-2 block text-sm font-bold " htmlFor="name">
             Feature Image
            </label> 
            <div >
            {courseData?.CatData.image?
                    <img height="200" width="200" src={`http://localhost:4001/${courseData.CatData.image}`} />:
                    <img height="200" width="200" src="/course.jpg" />}
            </div>
        
                    </div>
        </div>
      </div>
      </div>
    </>
  );
}
