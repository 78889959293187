import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import DragDropModule from "./DragComponents/DragDropModule";

import { topicGet, videoUpload, getQuestionDataDirectly, gradeGet, subjectGet, allactiveQuestion, GetCategoryAndSubcategory, testUpdate, chapterAllDataActive, imageAdd, chapterAllCategory, tagsAllData, testDataById } from "../../../proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./test.css";
import List from "../../../components/lecture/List";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { MultiSelect } from "react-multi-select-component";
import CkEditorComp from "components/common/CkEditorComp";
import VideoDragDrop from "./DragComponents/VideoDragDrop";
import { ImCancelCircle } from "react-icons/im";
import { useNavigate } from "react-router-dom";

const animatedComponents = makeAnimated();
export default function TestAdd() {
  const Router = useNavigate();
  const [name, setName] = useState(null);
  const [price, setPrice] = useState(null);
  const [note, setNote] = useState(null);
  const [selected, setSelected] = useState([]);
  const [selectedcat, setSelectedcat] = useState([]);
  const [optionsCat, setoptionsCat] = useState([]);
  const [options, setoptions] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [checkselectederror, setcheckselectederror] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [CatSubData, setCatSubData] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [image, setImage] = useState({ preview: '', data: '' })
  const [imagedata, setImageData] = useState(null)
  const [TagData, setTagData] = useState([]);
  const [sale_price, setSaleprice] = useState(null);
  const [checked, setChecked] = useState(false);
  const [questionData, setQuestionData] = useState("");
  const [gradeData, setGradeData] = useState("");
  const [subjectData, setSubjectData] = useState("");
  const [slug, setSlug] = useState(null);
  const [catData, setCatData] = useState(null);
  const [catDataFiroApi, setCatDataFromApi] = useState(null);
  const [videoFiles, setVideoFiles] = useState([]);
  const [videoUpdated, setVideoUpdate] = useState(false);
  const [videoRedUrl, setVideoRedUrl] = useState([]);
  const [videoFile, setVideoFile] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [isQuwstionAddPopup, setIsQuwstionAddPopup] = useState(false);
  const [isQuwstionViewPopup, setIsQuwstionViewPopup] = useState(false);
  // const [image, setImage] = useState(null);
  const [err, setErr] = useState();
  const [description, setDescription] = useState(null);
  const [questionsDefaultData, setQuestionsDefaultData] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [topicData, setTopicData] = useState("");

  const [status, setStatus] = useState("active");
  const [categoryType, setCategoryType] = useState([]);
  const [parentCategory, setParentCategory] = useState(null);
  //console.log(categoryType,98);
  const [submited, setSubmited] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedQuestiondata, setSelectedQuestiondata] = useState([]);
  const [videoLoading, setVideoLoading] = useState(false);
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');
  const [selectedQues, setselectedQues] = useState('');
  const [isImageLoadingUploading, setIsImageLoadingUploading] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  
  const handleHourChange = (e) => {
    setSelectedHour(e.target.value);
  };
  const handleMinuteChange = (e) => {
    setSelectedMinute(e.target.value);
  };
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minute = Array.from({ length: 60 }, (_, i) => i);
  const [isSticky, setIsSticky] = useState(false);
  const params = useParams();
  const { id } = params;
  const handleVideoFileChange = async (e) => {
    e.preventDefault();
    setVideoLoading(true);
    const chosenFiles = Array.prototype.slice.call(videoFiles);

    chosenFiles.map((data) => {

      if (!data) {
        alert("Please select a video file to upload.");
        return;
      }
      const chunkSize = 5 * 1024 * 1024;
      const totalChunks = Math.ceil(data.size / chunkSize);
      let chunkNumber = 0;
      let start = 0;
      let end = chunkSize;
      const uploadNextChunk = async () => {
        if (chunkNumber < totalChunks) {
          const chunk = data.slice(start, end);
          console.log(chunk, 'chunkchunk');
          const formData = new FormData();
          formData.append("Chunk", chunk);
          formData.append("ChunkNumber", chunkNumber);
          formData.append("TotalChunks", totalChunks);
          formData.append("FileName", data.name);
          formData.append("FileSize", data.size);
          // formData.append("File Size:", videoBuffer.length)

          try {
            const response = await axios.post(
              videoUpload,
              formData,
              {
                withCredentials: true,
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            ).then((res) => {

              if (res.status === 201) {
                setVideoRedUrl(prevData => [...prevData, res.data.url]);
                setVideoLoading(false);
                setVideoUpdate(true);
                toast.success("Video uploaded Successfully", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              else {
                console.log(res, 'resresres');
                const temp = `Chunk ${chunkNumber + 1}/${totalChunks} uploaded successfully`;
              }

              chunkNumber++;
              start = end;
              end = start + chunkSize;
              uploadNextChunk();

            })
              .catch((err) => {
                console.log(err);
                setVideoLoading(false);
              });
          } catch (error) {
            setVideoLoading(false);
            console.error("Error uploading chunk:", error);
            toast.error("Error During the uploading the video!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {

          setVideoFile(null);
          setVideoLoading(false);
        }
      };

      uploadNextChunk();
    })
  };
  const handleSubmitImage = async (e) => {
    e.preventDefault();
    setIsImageLoadingUploading(true);
    console.log(image.data, 'image111');


    let formData = new FormData()
    formData.append('file', image.data[0])

    axios
      .post(
        imageAdd,
        formData,

        {
          headers: {
            "Content-type": "multipart/form-data",
          },
          withCredentials: false,
        }
      ).then((res) => {
        if (res.status === 200) {
          setIsImageLoadingUploading(true);
          setIsImageUploaded(true);
          toast.success("Image uploaded Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setImageData(res.data.path);
          console.log("imagessaa",res.data.path);
          
          setErr(null);
          setIsImageUploaded(true);
          setSubmited(false);
        }

      })
      .catch((err) => {
        if (err.response.status === 400) {
          setIsImageLoadingUploading(true);
          setIsImageUploaded(false);
          toast.error("Please upload Image!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });
  }
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Set isSticky based on scroll position
      if (offset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach the event listener on component mount
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [chapterData, setChapterData] = useState(null);
  const gradeDataFetch = async () => {
    await axios
      .get(gradeGet)
      .then((res) => {
        setGradeData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setGradeData(null);
      });
  };
  const subjectDataFetch = async () => {
    await axios
      .get(subjectGet)
      .then((res) => {
        setSubjectData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setSubjectData(null);
      });
  };
  // Ck Editor Data Starts-----------------------{
  const QuestionDataFromEditor = (e) => {
    setQuestionData(e);
  };
  // Ck Editor Data Ends-----------------------}

  const getAllCategorysData = async () => {

    await axios.get(chapterAllDataActive, {
      withCredentials: true
    }).then(res => {
      //console.log(res.data.array,'resres');
      setChapterData(res.data.array);
      // setChapterData(res.data.data);

    }).catch(err => {
      setChapterData(null);
    })

    await axios.get(chapterAllCategory, {
      withCredentials: true
    }).then(res => {
      setoptionsCat(res.data.array);
    }).catch(err => {
      setoptionsCat(null);
    })


    await axios.get(allactiveQuestion, {
      withCredentials: true
    }).then(res => {
      setoptions(res.data.array);
    }).catch(err => {
      setoptionsCat(null);
    })

  }

  const handleImageChange = (e) => {
    const selectedImages = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files,
    }
    setImage(selectedImages);

  };



  ///////-----Submit Data Starts------//////////
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmited(true)
    await axios
      .post(
        testUpdate,
        {
          id,
          name,
          slug,
          description,
          selectedcat,
          parentCategory,
          status,
          imagedata,
          selected,
          selectedTags,
          selectedCategories,
          selectedSubject,
          selectedGrade,
          videoRedUrl,
          selectedQuestions,
          selectedTopic,
          timer: parseInt(selectedHour, 10) * 60 + parseInt(selectedMinute, 10)
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success("test Updated Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setErr(null);
        setSubmited(false);
        setTimeout(() =>{
          Router('/admin/test');
        },3000);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSubmited(false);
          setErr(err.response.data.errors);
        }
        else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });

    // console.log(selectedValues, 'selectedValues');
  }

  const fetchCatSubData = async () => {
    axios
      .get(GetCategoryAndSubcategory)
      .then((res) => {
        setCatSubData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setCatSubData([]);
      });
  };
  // CATEGORY OPTION .......... STARTING..........{
  const CategoryReData = [];
  if (CatSubData) {
    for (let i = 0; i < CatSubData.length; i++) {
      const id = CatSubData[i].id;
      const catName = CatSubData[i].name;
      const is_dissabled = CatSubData[i].status === "inactive" ? true : false;
      const option = {
        value: id,
        label: catName,
        disabled: is_dissabled,
      };
      CategoryReData.push(option);
      const subCatData = CatSubData[i].subcategories;
      if (subCatData[0]) {
        for (let index = 0; index < subCatData.length; index++) {
          const element = subCatData[index];
          const subCatId = element.id;
          const subCatName = element.name;
          const is_dissabled = element.status === "inactive" ? true : false;
          const option = {
            value: subCatId,
            label: subCatName,
            disabled: is_dissabled,
          };
          CategoryReData.push(option);
        }
      }
    }
  }
  // CATEGORY OPTION .......... ENDING..........}

  // TAG OPTION .......... STARTING..........{
  const TagReData = [];
  if (TagData) {
    for (let i = 0; i < TagData.length; i++) {
      const id = TagData[i].id;
      const tagName = TagData[i].name;
      const is_dissabled = TagData[i].status === "inactive" ? true : false;
      const option = {
        value: id,
        label: tagName,
        disabled: is_dissabled,
      };
      TagReData.push(option);
    }
  }

  const getCourseDataId = async () => {

    await axios.get(`${testDataById}/${id}`, {
      withCredentials: true
    }).then(res => {
      // console.log(res.data,'casasasaatDatacatData')
      setCatDataFromApi(res.data.CatData);
      setName(res.data.CatData.name)
      setSlug(res.data.CatData.slug)
      // setPrice(res.data.CatData.price)
      // setSaleprice(res.data.CatData.sale_price)
      setDescription(res.data.CatData.description)
      setNote(res.data.CatData.important_note)
       setSelectedTopic(res.data.CatData.topic.id)
      setSelectedTopic({
        label: res.data.CatData.topic.name,
        value: res.data.CatData.topic.id,
      });
      
      // console.log(res.data.CatData?.topic.name,'ttttttttttttttt');
      // console.log(res.data.CatData?.topic.id,'iiiiiiiiiiiiiiiii');

     
      setSelectedGrade({
        label: res.data.CatData?.grade?.name,
        value: res.data.CatData?.grade?.id,
      });
      setSelectedSubject({
        label: res.data.CatData?.subject.name,
        value: res.data.CatData?.subject.id,
      });
      setSelectedHour(Math.floor(res.data.CatData?.timer / 60));
      setSelectedMinute(res.data.CatData?.timer % 60);

      setSelectedQuestions(res.data.CatData?.bank_questions);  

      
        var arraytagt = new Array();
          res.data.CatData?.tags.forEach(function (element) {
          if (element.name && element.id) {
            arraytagt.push({
              label: element.name,
              value: element.id.toString(),
            });
          }
        });
        // console.log("Final array:", catDataFiroApi.tags);
        setSelectedTags(arraytagt);

      // category
      var catTest = [];
      res.data.CatData?.categories.forEach(function (element, i) {
        if (element.name != null) {
          var co_data = {
            label: element.name,
            value: element.id.toString(),
          };
          catTest.push(co_data);
        }

      });
      setSelectedCategories(catTest);


      var arrayChapter = new Array();
      res.data.Chapterdata.forEach(function (element, i) {
        if (element.chapter != null) {
          var co_data = {
            label: element.chapter?.name,
            value: element.chapter?.id.toString(),
          };
          arrayChapter.push(co_data);
        }

      });
      setSelectedChapter(arrayChapter);

      var arrayGrade = new Array();
      res.data.coursegrade.forEach(function (element, i) {
        if (element.grade != null) {
          var co_data = {
            label: element.grade?.name,
            value: element.grade?.id.toString(),
          };
          arrayGrade.push(co_data);
        }
        // console.log(arrayGrade,'arrayGrade');

      });
      setSelectedGrade(arrayGrade);
      const selectedImages = {
        preview: `http://localhost:4003/${res.data.CatData.image}`,
        data: res.data.CatData.image,
      }
      setImage(selectedImages);


      setCatData(res.data);
      //console.log(catData,'catDatacatData')
    }).catch(err => {
      setCatData(null);
    })

  }
  const topicDataFetch = async () => {
    await axios
      .post(
        topicGet,
        {
          subjectId: selectedSubject.value ? selectedSubject.value : 0,
          gradeId: selectedGrade.value ? selectedGrade.value : 0
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setTopicData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setTopicData(null);
      });
  };
  useEffect(() => {
    getCourseDataId();
  }, [id]);

  const fetchTagData = async () => {
    axios
      .get(tagsAllData)
      .then((res) => {
        setTagData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setTagData([]);
      });
  };
  useEffect(() => {
    fetchCatSubData();
    fetchTagData();
  }, []);
  useEffect(() => {
    getAllCategorysData();

  }, [])

  useEffect(() => {
    if (selected.length > 0) {
      //console.log(selected, 'selectedselected');
      setSelectedQuestiondata(selected);
    }
  }, [selected])

  useEffect(() => {
    if (selectedChapter.length > 0) {
      setSelectedChapter(selectedChapter);
    }

  }, [selectedChapter])
  useEffect(() => {
    gradeDataFetch();
    subjectDataFetch();
  }, []);
  const getQuesstionsDefalult = async () => {
    const dataQ = await axios.get(getQuestionDataDirectly).then((res) => {
      return res.data.data;
    }).catch((err) => {
      console.log('Error In Getting Question Data', err);
      return [];
    });


    setQuestionsDefaultData(dataQ);
  }
  useEffect(() => {
    // getdataFilter();
    topicDataFetch();

  }, [selectedSubject])
  useEffect(() => {
    getQuesstionsDefalult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // console.log(selectedTopic, 'selectedTopicselectedTopic')


   const getGradeData = async () => {
    return await axios.get(gradeGet).then((res) => res.data.formatteddata);
  };
  
   const getSubjectData = async () => {
    return await axios.get(subjectGet).then((res) => res.data.formatteddata);
  };
  useEffect(() => {
    const fetchInitialData = async () => {
      const grades = await getGradeData();
      const subjects = await getSubjectData();
      setGradeData(grades);
      setSubjectData(subjects);
    };
  
    fetchInitialData();
  }, []);
  return (

    <>

      <div className="mx-auto flex w-full  gap-7 py-6">

        <div className=" h-full w-[70%] rounded-md bg-white px-5 py-5  ">
          <div className="category_form mx-auto mt-3 w-full max-w-screen-xl rounded-lg bg-white p-6 dark:bg-navy-800">
            <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white">
              Update Test
            </h1>

            <div className="mb-4 flex gap-5 ">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                  Name
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="name"
                  type="text"
                  placeholder="Test Name"
                  value={name}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setName(newValue);

                    // Automatically generate slug based on the name
                    const newSlug = newValue
                      .toLowerCase() // Convert to lowercase
                      .replace(/\s+/g, "-") // Replace whitespace with hyphen
                      .replace(/[^a-z0-9-]/g, ""); // Remove special characters except hyphen

                    setSlug(newSlug);
                  }}
                />
                {err && err.name ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.name}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="slug">
                  Slug
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="slug"
                  type="text"
                  placeholder="test-slug"
                  value={slug}
                  onChange={(e) => {
                    setSlug(e.target.value);
                  }}
                />
                {err && err.slug ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.slug}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

            </div>
            <div className="mb-4">
              <label className="mb-2 block text-sm font-bold " htmlFor="slug">
                Video
              </label>

              <VideoDragDrop setVideoFiles={setVideoFiles} />
              {
                videoFiles[0] ? (
                  <>
                    {
                      videoLoading ? (
                        <>
                          <div className="text-end mt-3 ">
                            <button
                              disabled
                              className="bg-blue-200 text-white py-2 px-4 rounded-md hover:bg-blue-300 focus:outline-none"
                            >
                              Uploading....
                            </button>
                          </div>
                        </>
                      ) : <>
                        {
                          videoUpdated ? (
                            <>
                              <div className="text-end mt-3 ">
                                <button
                                  className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none"
                                >
                                  Uploaded
                                </button>
                              </div>
                            </>
                          ) : <>
                            <div className="text-end mt-3 ">
                              <button
                                onClick={handleVideoFileChange}
                                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none"
                              >
                                Upload Videos
                              </button>
                            </div>
                          </>
                        }
                      </>
                    }
                  </>
                ) : <></>
              }
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold "
                htmlFor="description"
              >
                Description
              </label>
              <CkEditorComp
                handleData={(e) => {
                  setDescription(e);
                }}
                predata={description ? description : ''}
              />
              {err && err.description ? (
                <>
                  <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    Description is Required!
                  </p>{" "}
                </>
              ) : (
                <></>
              )}

            </div>




            <div className="lectures_data" >
              <div class="flex items-center mb-4">


              </div>

              {/* <div className=" mb-4 flex gap-5 ">
                <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                  <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    checked={checked} // if the checkbox is checked (or not)
                    onChange={function (e) {
                      console.log(selectedCategories, 'selectedcat')
                      if (selectedCategories.length == 0) {
                        setChecked(checked)
                        setcheckselectederror('Please select category first')
                      }
                      else {
                        setChecked(!checked)
                        setcheckselectederror(null)
                      }

                    }}// toggles the checked state 

                  />


                  <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Search with same category as course</label>
                  <p className="mb-2 pl-1 pt-1 text-sm text-red-500">{checkselectederror ?
                    checkselectederror :
                    ''}</p>
                </div>

              </div>
              */}

              <div class="flex items-end justify-between  w-full ">

                <div className=" w-[50%] pr-3 ">
                  <label
                    className="mb-2 block text-sm font-bold "
                    htmlFor="description"
                  >
                    Add Question
                  </label>
                  <button onClick={() => {
                    setIsQuwstionAddPopup(!isQuwstionAddPopup);
                  }} className=" px-6 py-3 border border-lightPrimary/40   hover:bg-lightPrimary rounded-md hover:text-white text-lightPrimary w-full  " > Add  </button>
                  {/* question add popup start ---> */}
                  <div className={` ${isQuwstionAddPopup ? " translate-x-0 " : " translate-x-[100%] "} flex  items-center justify-center w-full z-[1000] h-screen fixed top-0 left-0 bg-white/10 backdrop-blur-xl `}>
                    <div className=" w-[90%] rounded-3xl h-[80%] relative bg-white  ">
                      {/* <DragDropModule setSelectedQuestionss={setSelectedQuestions} quedata={questionsDefaultData} /> */}
                      <DragDropModule 
                        quedata={questionsDefaultData} 
                        selectedQuestions={selectedQuestions} 
                        setSelectedQuestions={setSelectedQuestions} 
                      />
                      <button className=" absolute right-4 top-4  bg-white rounded-full " onClick={() => {
                        setIsQuwstionAddPopup(false);
                      }} > <ImCancelCircle className=" text-4xl " /> </button>
                    </div>
                  </div>
                </div>
                <div className="w-[50%] pl-3 ">
                  <button onClick={() => {
                    setIsQuwstionViewPopup(!isQuwstionViewPopup);
                  }} className=" px-6 py-3 border border-lightPrimary/40   hover:bg-lightPrimary rounded-md hover:text-white text-lightPrimary w-full  " > View selected questions  </button>
                  {/* question add popup start ---> */}
                  <div className={` ${isQuwstionViewPopup ? " translate-x-0 " : " translate-x-[100%] "} flex   items-center justify-center w-full z-[1000] h-screen fixed top-0 left-0 bg-white/10 backdrop-blur-xl `}>
                    <div className=" w-[40%] rounded-3xl pt-14  overflow-y-scroll px-6 pb-6   h-[80%] relative bg-white  ">
                      {
                        selectedQuestions[0] ? (
                          <>
                            {
                              selectedQuestions.map((value, index) => {
                                return (
                                  <>
                                    <div className=" my-2 py-2 bg-primaryBlue/20 px-3 rounded-sm ">
                                      <p>  {value.questions}</p>
                                    </div>
                                  </>
                                )
                              })
                            }
                          </>
                        ) : <> <div className=" w-full h-full flex items-center justify-center text-[40px] font-bold text-gray-300 ">No Questions Selected</div> </>
                      }

                    </div>
                    <button className=" absolute right-4 top-4  bg-white rounded-full " onClick={() => {
                      setIsQuwstionViewPopup(false);
                    }} > <ImCancelCircle className=" text-4xl " />  </button>
                  </div>

                </div>


              </div>
            </div>

            <div className="mb-4 flex-inline gap-5 ">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="name">
                  Timer
                </label>
                <div className="mb-4 flex gap-5 ">
                  <div className="mb-2">
                    <label htmlFor="hour"> Hour: </label>
                    <select id="hour" value={selectedHour}
                      onChange={handleHourChange}>
                      <option value="">Select</option>
                      {hours.map((hour) => (
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </select>

                  </div>
                  <div className="mb-2">
                    <label htmlFor="min"> Minute: </label>
                    <select id="min" value={selectedMinute} onChange={handleMinuteChange}>
                      <option value="">Select</option>
                      {minute.map((min) => (
                        <option key={min} value={min}>
                          {min}
                        </option>
                      ))}
                    </select>

                  </div>
                </div>
                <div className="mb-4 flex gap-5 ">
                  {selectedHour && <p>Selected    Hour: {selectedHour} </p>}   {selectedMinute && <p> MInute: {selectedMinute}</p>}
                </div>
                {err && err.timer ? (
                  <>
                    <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      Time is Required!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}

              </div>
            </div>

            <div className="mb-4 flex  gap-5">
              <div className="mb-4 w-full  md:w-1/2 lg:w-full">
                <label className="mb-2 block text-sm font-bold " htmlFor="status">
                  Status
                </label>

                <select
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"

                  id="status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>


            </div>



            {/* Additional fields can be added similarly */}

            <div className="flex justify-end">
              <button
                className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
                type="submit"
                onClick={handleSubmit}

              >
                Update Test
              </button>
            </div>
          </div>
        </div>
        {/* /// sidebar */}
        <div className={` relative w-[30%]  !h-[660px] `}>
          <div
            className={`  ${isSticky ? "relative !h-fit rounded-md   " : "relative"
              } w-full rounded-md bg-white py-20 px-5`}
          >
            <div className=" sticky  top-0 flex flex-col  gap-3 ">
              <div className="mb-0 w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Grade
                </label>
                {selectedGrade ? <>
                  <Select
                    defaultValue={selectedGrade}
                    onChange={setSelectedGrade}
                    options={gradeData}
                  />
                </> : ''}

                {err && err.selectedGrade ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedGrade}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-0 w-full ">
                <label
                  htmlFor="Subject"
                  className="block text-sm font-medium text-gray-600"
                >
                  Subject
                </label>
                {selectedSubject ?
                  <>
                    <Select
                      defaultValue={selectedSubject}
                      onChange={setSelectedSubject}
                      options={subjectData}
                    />
                  </> : ''}

                {err && err.selectedSubject ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedSubject}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-0 w-full ">
                <label
                  htmlFor="Subject"
                  className="block text-sm font-medium text-gray-600"
                >
                  Topic
                </label>
                {
                  selectedTopic?
                  <Select
                  defaultValue={selectedTopic}
                  onChange={setSelectedTopic}
                  options={topicData}
                />:'-'

                }
            
                {err && err.selectedTopic ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedTopic}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className=" w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Category
                </label>
                <MultiSelect
                  options={CategoryReData}
                  value={selectedCategories}
                  onChange={setSelectedCategories}
                  data={selectedCategories}
                  labelledBy="Select Category"
                  className=" mt-2"
                />
                {err && err.selectedCategories ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedCategories}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className=" w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Tag
                </label>

                <MultiSelect
                  options={TagReData}
                  value={selectedTags}
                  onChange={setSelectedTags}
                  data={selectedTags}
                  labelledBy="Select Category"
                  className=" mt-2"
                />
                {err && err.selectedTags ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.selectedTags}!
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>


              <div className="w-full">

                <div className="mb-4 w-full px-3 md:w-1/2 lg:w-full">
                  <form onSubmit={handleSubmitImage} enctype="multipart/form-data">

                    <label className="mb-2  block text-sm font-bold" htmlFor="image">
                      Feature Image
                    </label>

                    {image.preview && <img src={image.preview} width='100' height='100' />}
                    <hr></hr>
                    <input
                      type="file"
                      accept="image/*"
                      name="file"
                      className="focus:shadow-outline w-full appearance-none rounded   border leading-tight focus:outline-none"
                      id="image"
                      onChange={handleImageChange}
                    />


                    {/* <button class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" type='submit'>update</button> */}
                    {
                      image ? (
                        <>
                          {
                            isImageLoadingUploading ? (
                              <>
                                {
                                  isImageUploaded ? (
                                    <>
                                      <div className=" absolute right-2 bottom-2  flex  justify-end ">
                                        <button disabled className=" bg-green-500 text-white py-2 px-4 rounded-md  focus:outline-none" > Uploaded </button>
                                      </div>
                                    </>
                                  ) : <>
                                    <div className=" absolute right-2 bottom-2  flex  justify-end ">
                                      <button className=" bg-green-500/40 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none" > Uploading... </button>
                                    </div>
                                  </>
                                }
                              </>
                            ) : <>
                              <div className=" absolute right-2 bottom-2  flex  justify-end ">
                                <button onClick={handleSubmitImage} className=" bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none" > Upload </button>
                              </div>
                            </>
                          }
                        </>
                      ) : <></>
                    }
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <ToastContainer />
    </>
  );
}
