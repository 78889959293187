
import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { MdOutlineDoubleArrow  } from "react-icons/md";
import { RiEditCircleFill } from "react-icons/ri";
import { MdDelete   } from "react-icons/md";
import { categoryAllData,catDelete } from "proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "components/common/Pagination";
export default function Index() {
  // const categories = [1, 2, 3, 4, 5, 6,7];
  const [catData, setCatData] = useState([]);

   const [currentPage, setCurrentPage] = useState(1);
   const [recordsPerPage] = useState(10);

   // getting all data tags starts----------------------
   const getAllCategorysData = async () =>{

    await axios.get(categoryAllData,{
      withCredentials:true
    }).then(res =>{
    //  console.log(res.data.data,'casasasaatDatacatData')

      setCatData(res.data.data);
    }).catch(err =>{
      setCatData(null);
    })

  }

  const handleDelete =async (id) =>{
    await axios.delete(`${catDelete}/${id}`,{
     withCredentials:true
    }).then((res) =>{
     toast.success("Category Deleted Successfully!", {
       position: "top-center",
       autoClose: 2000,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
     })
     getAllCategorysData();
    }).catch((err) =>{
     if (err.response.status === 400) {
       toast.success("Category Not Found!", {
         position: "top-center",
         autoClose: 2000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       })
     }
      if (err.response.status === 500) {
       toast.success("Internal Server Error!", {
         position: "top-center",
         autoClose: 2000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       }) 
     }
    })
 }
  // getting all data tags ends----------------------
  useEffect(() =>{
    getAllCategorysData();
   // console.log(catData,'catDatacatData')
  },[])


  
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = catData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(catData.length / recordsPerPage);



  return (
    <>
      {/* <div><Link to='/admin/categories/add'>Add Category</Link></div>
    <div><Link to='/admin/categories/edit/7'>Edit Category</Link></div> */}
      <div className="mx-auto w-full rounded-lg overflow-hidden bg-white dark:bg-navy-800 max-w-screen-xl py-2 px-6">
       <div className=" flex w-full  justify-between items-center pb-2 ">
       <h1 className="mb-4 text-2xl font-bold dark:text-white  ">Category List</h1>
       <div className="">
        <Link className=" px-4 py-2 bg-white border-[3px] rounded-full font-bold hover:bg-blueSecondary hover:text-white border-primaryBlue/70" to={'/admin/categories/add'}> Add Category </Link>
       </div>
       </div>
        <div className="overflow-x-auto">
          <table className="min-w-full  rounded-lg overflow-hidden dark:bg-navy-900  bg-white">
            <thead className=" rounded-lg overflow-hidden text-white">
              <tr>
              <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Image</th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Name</th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Slug</th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Description</th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Status</th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-start">Parent Category</th>
                <th className=" bg-primaryBlue dark:bg-navy-900 dark:border-navy-900 border py-2 px-4 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
            {
              catData ? (
                <>
                 {currentRecords.map((category) => (
                <tr key={category.id}>
                  <td className="border py-2 dark:text-white   dark:border-navy-800   px-4"><img className="w-10 h-10 rounded" src={category.image} /> </td>
                  <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">{category.name} </td>
                  <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">/{category.slug}</td>
                  <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">{category.description}</td>
                  <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">
                    <p className=" text-sm  py-1 bg-blueSecondary w-fit px-4 text-white rounded-full" >Active</p>
                  </td>
                  <td className="border py-2 dark:text-white   dark:border-navy-800    px-4">{category.parentId ? category.parentId : '--' } </td>
                  <td className="border  dark:text-white  flex  gap-3 justify-center items-center  dark:border-navy-800   px-4 text-center">
                    <Link to={`/admin/categories/edit/${category.id}`}
                      className="focus:shadow-outline mr-2 rounded py-1    font-bold text-white focus:outline-none"
                      // onClick={() => handleEdit(category.id)}
                    >
                      <RiEditCircleFill className=" text-3xl hover:text-primaryBlue/80 dark:text-white text-primaryBlue" />
                    </Link>
                    {/* <button
                      className="focus:shadow-outline rounded font-bold text-white focus:outline-none"
                      // onClick={() => handleDelete(category.id)}
                    >
                      <MdDelete className=" text-3xl text-orange-600 hover:text-orange-500 "  />
                    </button> */}

                     <button
                      className="focus:shadow-outline rounded font-bold text-white focus:outline-none"
                      onClick={() => handleDelete(category.id)}
                    >
                      <MdDelete className=" text-3xl text-orange-600 hover:text-orange-500 "  />
                    </button>
                  </td>
                </tr>
               ))}
               </>
             ) : <>
              <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">No Data</td>
                 <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">No Data</td>
                 <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">No Data</td>
                 <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">No Data</td>
                 <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">No Data</td>
                 <td className="border py-2 dark:text-white   dark:border-navy-800   px-4">N/A</td>
             </>
            }
            </tbody>
          </table>
        </div>
        {
          nPages != 1 ?
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            :
            ''
        }
      </div>
      <ToastContainer/>
    </>
  );
}
