import React, { useState } from "react";
import axios from "axios";
import { tagAdd } from "../../../proxyUrl";
import LoaderComp from "components/common/LoaderComp";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
export default function TagAdd() {
  const Navigate = useNavigate();
  const [name, setName] = useState(null);
  const [slug, setSlug] = useState(null);
  const [description, setDescription] = useState(null);

  const [status, setStatus] = useState("active");
  const [submited, setSubmited] = useState(false);

  // response err from the server
  const [err, setErr] = useState();

  ///////-----Submit Data Starts------//////////
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmited(true);

    await axios
      .post(
        tagAdd,
        {
          name,
          slug,
          description,
          status,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success("Tag Created Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() =>{
          Navigate("/admin/tag");
        },3000)

        setErr(null);
        setSubmited(false);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSubmited(false);
          setErr(err.response.data.errors);
        }
        else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
       return;
      });
  };

  return (
    <>
      <div className=" relative">
      <div className="category_form mx-auto mt-3 w-full max-w-screen-xl rounded-lg bg-white p-6 dark:bg-navy-800">
        <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white">
          Add Tag
        </h1>

        <div className="mb-4 flex gap-5 ">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="name">
              Name
            </label>
            <input
              className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
              id="name"
              type="text"
              placeholder="Tag Name"
              value={name}
              onChange={(e) => {
                const newValue = e.target.value;
                setName(newValue);

                // Automatically generate slug based on the name
                const newSlug = newValue
                  .toLowerCase() // Convert to lowercase
                  .replace(/\s+/g, "-") // Replace whitespace with hyphen
                  .replace(/[^a-z0-9-]/g, ""); // Remove special characters except hyphen

                setSlug(newSlug);
              }}
            />
            {err && err.name ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err.name}!
                </p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="slug">
              Slug
            </label>
            <input
              className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
              id="slug"
              type="text"
              placeholder="tag-slug"
              value={slug}
              onChange={(e) => {
                setSlug(e.target.value);
              }}
            />
            {err && err.slug ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err.slug}!
                </p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="status">
              Status
            </label>
            <select
              className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
              id="status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
        </div>

        <div className="mb-4">
          <label
            className="mb-2 block text-sm font-bold "
            htmlFor="description"
          >
            Description
          </label>
          <textarea
            className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
            id="description"
            placeholder="tag Description"
            rows="3"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          ></textarea>
          {err && err.description ? (
            <>
              {" "}
              <p className=" pl-1 pt-1 text-sm text-red-500">
                {" "}
                {err.description}!
              </p>{" "}
            </>
          ) : (
            <></>
          )}
        </div>
        {/* Additional fields can be added similarly */}

        <div className="flex justify-end">
          <button
            className="button_primary"
            type="submit"
            onClick={handleSubmit}
          >
            Add Tag
          </button>
        </div>
      </div>
      <LoaderComp submited={submited} />
      </div>
      <ToastContainer />
    </>
  );
}
