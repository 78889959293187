import React, { useState, useEffect } from "react";
import CkEditorComp from "components/common/CkEditorComp";

import axios from "axios";
import {
  AddTopic,
  gradeGet,
  subjectGet,
  GetPerentCat,
} from "../../../proxyUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Category/category.css";
import LoaderComp from "components/common/LoaderComp";
import { useNavigate } from "react-router-dom"  ;
export default function TopicAdd() {
  const  navigate = useNavigate();
  const [name, setName] = useState(null);
  const [slug, setSlug] = useState(null);
  const [image, setImage] = useState(null);
  const [err, setErr] = useState();
  const [description, setDescription] = useState(null);
  const [optionstopic, setOptionstopic] = useState([
   
  ]);
  const [status, setStatus] = useState("active");
  const [categoryType, setCategoryType] = useState("0");
  const [parentCategory, setParentCategory] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [PerentCatDataFromApi, setPerentCatDataFromApi] = useState(null);

  const [gradeId, setGradeId] = useState("");
  const [subjectId, setSubjectId] = useState("");

  const [grade, setGrade] = useState([]);
  const [subject, setSubject] = useState([]);

  

  const fetchAllGeade = async () => {
    await axios
      .get(gradeGet)
      .then((res) => {
        setGrade(res.data.data);
      })
      .catch((err) => {
        setGrade([]);
      });
  };
  const fetchAllSubject = async () => {
    await axios
      .get(subjectGet)
      .then((res) => {
        setSubject(res.data.data);
      })
      .catch((err) => {
        setSubject([]);
      });
  };
  useEffect(() => {
    fetchAllGeade();
    fetchAllSubject();
  }, []);
  const getAllPerentCatData = async () => {
    axios
      .get(GetPerentCat)
      .then((res) => {
        setPerentCatDataFromApi(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setPerentCatDataFromApi(null);
      });
  };

  useEffect(() => {
    getAllPerentCatData();
  }, []);

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    // You can perform additional checks or validations here if needed
    setImage(selectedImage);
  };
  const handleCategoryTypeChange = (e) => {
    setCategoryType(e.target.value);
    // Reset parent category when switching between category and subcategory
    setParentCategory(null);
  };

  ///////-----Submit Data Starts------//////////
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmited(true);
    await axios
      .post(
        AddTopic,
        {
          name: name,
          slug: slug,
          subject_id: subjectId,
          grade_id: gradeId,
          description:description,
          subtopic:optionstopic
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success("Topic Created Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setErr(null);
        setSubmited(false);
        setTimeout(() =>{
          navigate('/admin/topic');
        },3000)
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSubmited(false);
          setErr(err.response.data.errors);
        } else if (err.response.status === 500) {
          toast.error("Something Went Wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErr(null);
          setSubmited(false);
        }
        setSubmited(false);
        return;
      });
  };
  const handleInputChange = (index, field, value) => {
    console.log(index, field, value,'valuevaluevaluevalue');

     const newOptions = [...optionstopic];
     newOptions[index][field] = value;
     // console.log(newOptions,'newOptionsnewOptionsnewOptionss');
     setOptionstopic(newOptions);
   };
  const removeInputFields = (index) => {
    const updatedFields = [...optionstopic];
    updatedFields.splice(index, 1);
    setOptionstopic(updatedFields);
  };
  const renderInputFields = () => {
    return optionstopic.map((option, index) => (
      <div key={index}>
        <div className=" border border-blueSecondary px-7  py-4 ">
          <div className="">
            <p className=" pb-1 font-bold text-primaryBlue">
              Sub Topic {index + 1}
            </p>
          </div>
          <div className=" flex w-full  md:w-1/2 lg:w-full">
            <div class="w-[100%] ">
              <div className="mb-0 w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Title
                </label>
              
                <input
              className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
              id="name"
              type="text"
              placeholder="Title Name"
              value={optionstopic[index].title}
              onChange={(e) =>
                handleInputChange(index, "title", e.target.value)
              }
            />
             {err && err["title"] ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err["title"]}{" "}
                </p>{" "}
              </>
            ) : (
              <></>
            )}
              </div>  

            </div>
          </div>
          <div className=" flex w-full  md:w-1/2 lg:w-full">
            <div class="w-[100%] ">
              <div className="mb-0 w-full ">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-600"
                >
                  Description
                </label>
           
              <CkEditorComp
                handleData={(e) => {
               //   setDescription(e);
               handleInputChange(index, "description", e)
                }}
              />
           {err && err["description"] ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err["description"]}{" "}
                </p>{" "}
              </>
            ) : (
              <></>
            )}
              </div>

            </div>
          </div>

          
          <div className="mb-4">


          </div>
          {index === 0 ? (
            <></>
          ) : (
            <>
              <div className=" flex w-full items-center justify-end">
                <button
                  className=" rounded-sm border border-red-500 px-4 py-1 text-sm text-red-500 "
                  type="button"
                  onClick={() => removeInputFields(index)}
                >
                  Remove Topic
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    ));
  };
  return (
    <>
      <div className="category_form relative  mx-auto mt-3 w-full max-w-screen-xl rounded-lg  p-6 dark:bg-navy-800">
        <h1 className="mb-4 text-2xl font-bold text-primaryBlue dark:text-white">
          Add Topic
        </h1>

        <div className="mb-4 flex gap-5 ">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="name">
              Name
            </label>
            <input
              className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
              id="name"
              type="text"
              placeholder="Topic Name"
              value={name}
              onChange={(e) => {
                const newValue = e.target.value;
                setName(newValue);

                // Automatically generate slug based on the name
                const newSlug = newValue
                  .toLowerCase() // Convert to lowercase
                  .replace(/\s+/g, "-") // Replace whitespace with hyphen
                  .replace(/[^a-z0-9-]/g, ""); // Remove special characters except hyphen

                setSlug(newSlug);
              }}
            />
            {err && err.name ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err.name}{" "}
                </p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="slug">
              Slug
            </label>
            <input
              className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
              id="slug"
              type="text"
              placeholder="topic-slug"
              value={slug}
              onChange={(e) => {
                setSlug(e.target.value);
              }}
            />
            {err && err.slug ? (
              <>
                {" "}
                <p className=" pl-1 pt-1 text-sm text-red-500">
                  {" "}
                  {err.slug}
                </p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="mb-4 flex  gap-5">
          <div className="mb-4 w-full  md:w-1/2 lg:w-full">
            <label className="mb-2 block text-sm font-bold " htmlFor="status">
              Select Grade
            </label>
            {grade[0] ? (
              <>
                <select
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="status"
                  value={gradeId}
                  onChange={(e) => {
                    setGradeId(e.target.value);
                  }}
                >
                  <option value={""}> Select Grade... </option>
                  {grade.map((value, index) => {
                    return (
                      <>
                        <option value={value.id}>{value.name}</option>
                      </>
                    );
                  })}
                </select>
                {err && err.grade_id ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.grade_id}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="mb-4 w-full md:w-1/2 lg:w-full">
            <label
              className="mb-2 block text-sm font-bold "
              htmlFor="categoryType"
            >
              Select Subject
            </label>
            {subject[0] ? (
              <>
                <select
                  className="focus:shadow-outline w-full appearance-none rounded border  leading-tight  focus:outline-none"
                  id="categoryType"
                  value={subjectId}
                  onChange={(e) => {
                    setSubjectId(e.target.value);
                  }}
                >
                  <option value={""}> Select Subject... </option>
                  {subject.map((value, index) => {
                    return (
                      <>
                        <option key={index} value={value.id}>
                          {" "}
                          {value.name}{" "}
                        </option>
                      </>
                    );
                  })}
                </select>
                   {err && err.subject_id ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err.subject_id}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold "
                htmlFor="description"
              >
                Description
              </label>
              <CkEditorComp
                handleData={(e) => {
                  setDescription(e);
                }}
              />
              {err && err.description ? (
                <>
                  <p className=" mt-[-16px] pl-1 pt-1 text-sm text-red-500">
                    {" "}
                    Description is Required!
                  </p>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="category_form relative  mx-auto mt-3 w-full max-w-screen-xl rounded-lg   dark:bg-navy-800">
                {renderInputFields()}
                {err && err["options"] ? (
                  <>
                    {" "}
                    <p className=" pl-1 pt-1 text-sm text-red-500">
                      {" "}
                      {err["options"]}
                    </p>{" "}
                  </>
                ) : (
                  <></>
                )}
                <div
                  className=" mt-3 w-fit rounded-md bg-primaryBlue  px-5 py-2 text-white "
                  onClick={() =>
                    setOptionstopic([
                      ...optionstopic,
                      {
                        title: [],
                        description: [],

                      },
                    ])
                  }
                >
                  Add Sub Topic
                </div>
                <LoaderComp submited={submited} />
              </div>
        {/* Additional fields can be added similarly */}

        <div className="flex justify-end">
          <button
            className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
            type="submit"
            onClick={handleSubmit}
          >
            Add Subtopic
          </button>
        </div>
        <LoaderComp submited={submited} />
      </div>

      <ToastContainer />
    </>
  );
}
