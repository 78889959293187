import React, { useState, useEffect,useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { QuestionAllGet,gradeGet,subjectGet,topicGet,subTopicFilter,questionFilter } from "proxyUrl";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { RiEditCircleFill , RiShowersFill} from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import he from 'he';
import Pagination from '../../../components/common/Pagination';
import Records from './Record';
import LoaderComp from "components/common/LoaderComp";
import Select from "react-select";

export default function Index() {
  const [err, setErr] = useState();
  const [filter, setFilter] = useState(0);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [totalcount, setTotalcount] = useState(0);
  const [gradeData, setGradeData] = useState("");
  const [topicData, setTopicData] = useState("");
  const [subtopicData, setSubTopicData] = useState("");
  const [subjectData, setSubjectData] = useState("");
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);

  // fetching main question data start ---------------{
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage] = useState(25);
    const [QuestionMainDataFromApi, setQuestionMainDataFromApi] = useState([]);
   // console.log(QuestionMainDataFromApi,"QuestionMainDataFromApi");
    const [submited, setSubmited] = useState(true);

   const gradeDataFetch = async () => {
    await axios
      .get(gradeGet)
      .then((res) => {
        setGradeData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setGradeData(null);
      });
  };
  const subjectDataFetch = async () => {
    await axios
      .get(subjectGet)
      .then((res) => {
        setSubjectData(res.data.formatteddata);
      })
      .catch((err) => {
        console.log(err);
        setSubjectData(null);
      });
  };
  const topicDataFetch = async () => {
    await axios
      .post(
        topicGet,
        {
          subjectId: selectedSubject ? selectedSubject.value : 0,
          gradeId: selectedGrade ? selectedGrade.value:0,
        },
        {
          withCredentials: true,  
        }
      )
      .then((res) => {
        setTopicData(res.data.formatteddata);
        
      })
      .catch((err) => {
        console.log(err);
        setTopicData(null);
      });
  };
  const subtopicDataFetch = async () => {
    await axios
      .post(
        subTopicFilter,
        {
          topicid: selectedTopic ? selectedTopic.value : 0,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
         setSubTopicData(res.data.formattedData);
      })
      .catch((err) => {
        console.log(err);
        setSubTopicData(null);
      });
  };
  const getdataFilter = async () => {
    await axios
      .post(
        questionFilter,
        { 
            page : currentPage,
            type: 1,
            gradeId: selectedGrade ? selectedGrade.value:0,
            subjectId: selectedSubject ? selectedSubject.value:0,
            topicId:selectedTopic ? selectedTopic.value:0

        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setTotalcount(res?.data?.count)
        // setQuestionMainDataFromApi(res?.data?.myArray?.data);
        setQuestionMainDataFromApi(res?.data.data);
        console.log("count data", res?.data?.count);
        
        setFilter(1);
        setSubmited(false);

      })
      .catch((err) => {
        console.log(err);
        setQuestionMainDataFromApi([]);
      });
  };
   const getQuestionFun = async () => {
  //  setSubmited(true)
    await axios
      .post(QuestionAllGet,
        {
          page:currentPage,
          type: 0
        })
      .then((res) => {
        setLoading(false);
        setTotalcount(res?.data?.myArray?.count)
        setFilter(0);
        // setQuestionMainDataFromApi(res?.data?.myArray?.data);
        setQuestionMainDataFromApi(res?.data.data);
        console.log("questttt",res?.data.data);
        
        setSubmited(false);
      })
      .catch((err) => {
        setQuestionMainDataFromApi([]);
      });
  };
  //  Call the API when component mounts
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = QuestionMainDataFromApi?QuestionMainDataFromApi.slice(indexOfFirstRecord, indexOfLastRecord):0;
  const  pagelength = totalcount?totalcount:0 ;


  const nPages = Math.ceil(pagelength/ recordsPerPage);
 
  useEffect(() => {
    getQuestionFun();
  }, []);
  useLayoutEffect(() => {
    gradeDataFetch();
    subjectDataFetch();
   
  }, []);
  useEffect(() => {
    setCurrentPage(0)
    topicDataFetch();
    getdataFilter();

  }, [selectedSubject]);
  useEffect(() => {
    setCurrentPage(0)

    subtopicDataFetch();
    getdataFilter();

    setSelectedTopic(null);
}, [selectedTopic]);


useEffect(() => {
  ///setLoading(true);
  setSubmited(true);
  if(filter == 0){
    
    getQuestionFun();
  }
  else{
 getdataFilter();
  }
 
}, [currentPage]);

useEffect(() => {
  getdataFilter();
  setSelectedSubject(null);
  setSelectedTopic(null);
  setCurrentPage(0)

}, [selectedGrade]);
// console.log(submited,'submitedsubmitedsubmited');

  // --------------------------------------------}
// console.log(QuestionMainDataFromApi,'dddd');
  return (
    <>
      <div className="mx-auto w-full max-w-screen-xl overflow-hidden rounded-lg bg-white py-2 px-6 dark:bg-navy-800">
        <div className=" flex w-full  items-center justify-between pb-2 ">
          <h1 className="mb-4 text-2xl font-bold dark:text-white  ">
            Questions List
          </h1>
          <div className="">
            <Link
              className=" rounded-full border-[3px] border-primaryBlue/70 bg-white px-4 py-2 font-bold hover:bg-blueSecondary hover:text-white"
              to={"/admin/questions/add"}
            >
              {" "}
              Add Question{" "}
            </Link>
          </div>
        </div>
        <div class="grid grid-cols-4 gap-4 my-10">
        <div className="mb-0 w-full ">
                  <label
                    htmlFor="category"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Grade
                  </label>
                  <Select
                    defaultValue={selectedGrade}
                    onChange={setSelectedGrade}
                    options={gradeData}
                  />
                  {err && err["grade"] ? (
                    <>
                      {" "}
                      <p className=" pl-1 pt-1 text-sm text-red-500">
                        {" "}
                        {err["grade"]}
                      </p>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mb-0 w-full ">
                  <label
                    htmlFor="Subject"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Subject
                  </label>
                  <Select
                    defaultValue={selectedSubject?selectedSubject:null}
                    onChange={setSelectedSubject}
                    options={subjectData}
                  />
                  {err && err["subject"] ? (
                    <>
                      {" "}
                      <p className=" pl-1 pt-1 text-sm text-red-500">
                        {" "}
                        {err["subject"]}
                      </p>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="mb-0 w-full ">
                  <label
                    htmlFor="category"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Topic
                  </label>
                  <Select
                    defaultValue={selectedTopic}
                    onChange={setSelectedTopic}
                    options={topicData ? topicData : []}
                  />
                  {err && err["topic"] ? (
                    <>
                      {" "}
                      <p className=" pl-1 pt-1 text-sm text-red-500">
                        {" "}
                        {err["topic"]}
                      </p>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {/* <div className="mb-0 w-full ">
                  <label
                    htmlFor="category"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Sub Topic
                  </label>
                  <Select
                    defaultValue={selectedSubTopic}
                    onChange={setSelectedSubTopic}
                    options={subtopicData ? subtopicData : []}
                  />
                  {err && err["subtopic"] ? (
                    <>
                      {" "}
                      <p className=" pl-1 pt-1 text-sm text-red-500">
                        {" "}
                        {err["subtopic"]}
                      </p>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </div> */}
        </div>
        <div className="overflow-x-auto">
        <LoaderComp submited={submited} />
        <Records nPages={25*(currentPage)} getQuestionFun={getQuestionFun} data={QuestionMainDataFromApi}/>
        {
              nPages != 1 ?
                      <Pagination
                          
                          nPages={nPages}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                      />
              :
              ''

            }
        </div>
        
      </div>
    </>
  );
}
