//  export const host = "http://localhost:4003";
 export const host = "https://lmsapi.vnaya.com";
//export const host = "http://192.168.16.22:4003";


export const loginRoute = host+'/signin';
export const logOut = host+'/admin/sign-out';
export const userAuthentication = host+'/user/authentication';

export const questionDataById = host+'/questionDataById';

export const tagAdd = host+'/tagadd';
export const categoryAdd = host+'/categoryadd';
export const tagsAllData = host+'/tagall';
export const categoryAllData = host+'/categoryall';
export const categoryDataById = host+'/categorybyid';
export const categoryUpdate = host+'/categoryUpdate';
export const catDelete = host+'/catdelete';
export const GetPerentCat = host+'/getperentcat';
export const GetCategoryAndSubcategory = host+'/getcategorysubcategory';
export const allactiveQuestion = host+'/getquestionsdata';
export const chapterAllCategory = host+'/chapterallcategory';
export const getQuestionsByfilter = host+'/getQuestionsByfilter';


// export const imageAdd = host+'/imageadd';

export const chapterDatalist = host+'/chapterDatalist';
export const questionDatalist = host+'/questionDatalist';
export const questionDatalistfilter = host+'/questionDatalistfilter';

export const contentAdd = host+'/contentadd';
export const contentAllData = host+'/contentall';
export const contentDelete = host+'/contentdelete';

export const contentDataById = host+'/contentbyid';
export const contentUpdate = host+'/contentUpdate';


export const testAdd = host+'/testAdd';
export const testAllData = host+'/testall';
export const testDelete = host+'/testdelete';
export const testDataById = host+'/testbyid';
export const testUpdate = host+'/testUpdate';


export const courseAdd = host+'/courseadd';
export const courseAllData = host+'/courseall';
export const courseDataById = host+'/coursebyid';
export const courseUpdate = host+'/courseUpdate';
export const courseDelete = host+'/coursedelete';
export const courseShow = host+'/courseshow';
export const videoUpload = host+'/video/upload';
export const chapterAdd = host+'/chapteradd';
export const chapterAllDataActive = host+'/chapterallactive';
export const chapterAllData = host+'/chapterall';
export const chapterDataById = host+'/chapterbyid';
export const chapterUpdate = host+'/chapterUpdate';
export const chapterDelete = host+'/chapterdelete';
export const chapterShow = host+'/chaptershow';
export const questionShow = host+'/questionshow';

export const fetchSubjectbygrade = host+'/subjectsbygrade';

export const getQuestionDataDirectly = host+'/getallquestionsdirect';
export const QuestionUpdateApi = host+'/updatequestion';
export const questionDelete = host+'/questiondelete';
export const allQuestions = host+'/getallquestions';
export const questionFilter = host+'/getallquestionsFilter';


export const QuestionAddApi = host+'/addquestion';
export const QuestionAllGet = host+'/getquestions';

export const tagEdit = host+'/tagupdate';
export const tagDelete = host+'/tagsdelete'
export const tagById = host+'/tagdatabyid';

export const gradeGet = host+'/grade';
export const topicGet = host+'/topic'
export const subTopicGet = host+'/subtopic';
export const subTopicFilter = host+'/subtopicfilter';

export const subjectGet = host+'/subjects';
export const questionGet = host+'/question';


export const AddTopic = host+'/addtopic';
export const getTopicDataWithPagination = host+'/gettopicdatawithpagination';
export const getSingleTopicById = host+'/topicdatabyid';
export const updateTopic = host+'/topicupdate';
export const DeleteTopic = host+'/topicdelete';

// export const getUserList = host+'/admin/user/list';
export const addResister = host+'/register/add';
export const getAllUserList = host+'/user/list';
export const getOneUserById = host+'/getoneuserbyid';
export const UploadUserImage = host+'/upload_user_image';
export const userLogout = host+'/admin/sign-out';  
export const formApprove = host+'/approve';
export const GetBookingDataByUserId = host+'/get/registerdata';
export const GetOneBookingDataById = host+'/register/get';
export const EditBookingDataById = host+'/register/edit';
export const DeleteBookingDataById = host+'/register/delete';
export const GetUserRoles = host+'/role';
export const getpermitions = host+'/getpermissions';
export const addUser = host+'/useradd';
export const getManagerData= host+'/managerData';
export const getTlData= host+'/teamleaderData';
export const getUserDataByToken = host+'/getuserinfo';

export const addBlogs = host+'/creedblog';
export const getAllBlog = host+'/blog/fetch';
export const GetBlogsDataById = host+'/blog/getSingleAdminBlog/';
export const UpdateBlogData = host+'/blog/blog_update';
export const DeleteBlogData = host+'/blog/blog_delete';
export const UploadImage= host+'/imageupload';

export const imageAdd= host+'/featureimageupload';


//  job api
export const getAllJob = host+'/getjob';
export const postJob = host+'/jobcreate';
export const updateJob = host+'/updatejob';
export const getjobdatabyid = host+'/getjobbyid';
export const UploadJobIcon = host+"/api/jobiconupload";

export const  singleSurveyDataById = host+'/api/singlesurvaydata';
export const  surveyDataAllPercentageAndCount = host+'/api/getalloveraverage';
export const  surveyquestionsdata = host+'/api/geteveryquestionsanswerpercentage';
